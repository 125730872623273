import { useQuery } from "@tanstack/react-query";
import { getYoutube } from "../../http/youtubeController";

const useYoutube = () => {
  return useQuery({
    queryKey: ["youtube"],
    queryFn: getYoutube,
  });
};

export default useYoutube;
