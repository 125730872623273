import React from "react";
import { useState, useEffect } from "react";
import { getChannelMembers, getUsers, getUsersInvoice } from "../http/userAPI";
import { observer } from "mobx-react-lite";
import "./styles/Main.css";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

const Main = observer(() => {
  const [users, setUsers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [channelMembers, setChannelMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [fetchedUsers, fetchedInvoices, fetchedChannelMembers] = await Promise.all([
          getUsers(),
          getUsersInvoice(),
          getChannelMembers()
        ]);
        
        setUsers(fetchedUsers);
        setInvoices(fetchedInvoices);
        setChannelMembers(fetchedChannelMembers);
      } catch (error) {
        console.error("Помилка завантаження даних:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const activeSubscriptions = users.filter((user) => user.isSubscribe);

  const totalAmount = users.reduce((acc, user) => acc + user.totalAmount, 0);

  // Calculate total amounts for the current month
  const calculateTotalForCurrentMonth = (invoices) => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth(); // Months are 0-indexed, January is 0

    const startDate = new Date(currentYear, currentMonth, 1);
    const endDate = new Date(currentYear, currentMonth + 1, 0); // Last day of the month

    const filteredInvoices = invoices.filter((invoice) => {
      const paidDate = new Date(invoice.paidDate);
      const isWithinMonth = paidDate >= startDate && paidDate <= endDate;
      return invoice.status === "approved" && isWithinMonth;
    });

    const totalAmount = filteredInvoices.reduce((acc, invoice) => {
      const amount =
        typeof invoice.amount === "string"
          ? parseInt(invoice.amount, 10)
          : invoice.amount; // Convert to number if string
      return acc + amount;
    }, 0);

    return totalAmount;
  };

  const totalAmountCurrentMonth = calculateTotalForCurrentMonth(invoices);

  ChartJS.register(ArcElement, Tooltip, Legend);

  // Кольори в стилі ФК
  const chartColors = [
    "#e30613", // Основний червоний
    "#c10510", // Темніший червоний 
    "#f26a73", // Світліший червоний
    "#222222", // Чорний
    "#444444", // Темно-сірий
    "#777777", // Сірий
  ];

  const subscriptionData = {
    labels: [
      "Місяць",
      "Три місяці",
      "Пів року",
      "Один рік",
    ],
    datasets: [
      {
        label: "Підписки",
        data: [
          users.filter((user) => user.subDuration === "1month").length,
          users.filter((user) => user.subDuration === "3month").length,
          users.filter((user) => user.subDuration === "6month").length,
          users.filter((user) => user.subDuration === "12month").length,
        ],
        backgroundColor: chartColors,
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: {
          size: 14,
          weight: 'bold'
        },
        bodyFont: {
          size: 13
        },
        padding: 12,
        displayColors: true,
        usePointStyle: true,
      }
    },
    cutout: '70%',
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  // Додаткові дані про користувачів для відображення
  const userStats = [
    {
      title: "Користувачів у боті",
      value: users.length,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
        </svg>
      ),
    },
    {
      title: "Користувачів у каналі",
      value: channelMembers,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21.5 2v6h-6"></path>
          <path d="M2.5 22v-6h6"></path>
          <path d="M22 11.5A10 10 0 0 0 3.2 7.2M2 12.5a10 10 0 0 0 18.8 4.2"></path>
        </svg>
      ),
    },
    {
      title: "Активних підписників",
      value: activeSubscriptions.length,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M20 6L9 17l-5-5"></path>
        </svg>
      ),
    },
    {
      title: "Всього зароблено",
      value: `${totalAmount} грн`,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
    },
    {
      title: "Всього за місяць",
      value: `${totalAmountCurrentMonth} грн`,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
          <line x1="16" y1="2" x2="16" y2="6"></line>
          <line x1="8" y1="2" x2="8" y2="6"></line>
          <line x1="3" y1="10" x2="21" y2="10"></line>
        </svg>
      ),
    },
  ];

  // Дані про конверсію користувачів
  const activePercentage = users.length > 0 
    ? Math.round((activeSubscriptions.length / users.length) * 100) 
    : 0;

  if (isLoading) {
    return (
      <div className="dashboard-loading">
        <div className="spinner-large"></div>
        <p>Завантаження даних панелі адміністратора...</p>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>Статистика FC Мрія</h1>
        <p className="dashboard-subtitle">Загальна інформація про користувачів та фінанси</p>
      </div>
      
      <div className="stats-grid">
        {userStats.map((stat, index) => (
          <div className="stat-card" key={index}>
            <div className="stat-icon">{stat.icon}</div>
            <div className="stat-content">
              <h3 className="stat-title">{stat.title}</h3>
              <div className="stat-value">{stat.value}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="charts-container">
        <div className="chart-card">
          <div className="chart-header">
            <h2>Типи підписок</h2>
            <p>Розподіл активних підписників за тривалістю підписки</p>
          </div>
          
          <div className="chart-content">
            <div className="chart-doughnut">
              <Doughnut data={subscriptionData} options={chartOptions} />
              <div className="doughnut-center">
                <span className="doughnut-total">{activeSubscriptions.length}</span>
                <span className="doughnut-label">Підписки</span>
              </div>
            </div>
            
            <div className="chart-legend">
              {subscriptionData.labels.map((label, index) => (
                <div className="legend-item" key={index}>
                  <div className="legend-color" style={{ backgroundColor: chartColors[index] }}></div>
                  <div className="legend-info">
                    <div className="legend-label">{label}</div>
                    <div className="legend-value">
                      <span className="legend-count">{subscriptionData.datasets[0].data[index]}</span>
                      <span className="legend-percent">
                        ({subscriptionData.datasets[0].data[index] > 0 && subscriptionData.datasets[0].data.reduce((a, b) => a + b, 0) > 0
                          ? ((subscriptionData.datasets[0].data[index] / subscriptionData.datasets[0].data.reduce((a, b) => a + b, 0)) * 100).toFixed(1)
                          : 0}%)
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="chart-card">
          <div className="chart-header">
            <h2>Активність користувачів</h2>
            <p>Співвідношення активних підписників до загальної кількості користувачів</p>
          </div>
          
          <div className="chart-content conversion-stats">
            <div className="conversion-indicator">
              <div className="progress-circle">
                <svg viewBox="0 0 36 36" className="circular-chart">
                  <path className="circle-bg"
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path className="circle"
                    strokeDasharray={`${activePercentage}, 100`}
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <text x="18" y="20.35" className="percentage">{activePercentage}%</text>
                </svg>
              </div>
            </div>
            
            <div className="conversion-stats-details">
              <div className="conversion-stat">
                <div className="stat-label">Загальна кількість користувачів</div>
                <div className="stat-number">{users.length}</div>
              </div>
              <div className="conversion-stat">
                <div className="stat-label">Активні підписники</div>
                <div className="stat-number">{activeSubscriptions.length}</div>
              </div>
              <div className="conversion-stat">
                <div className="stat-label">Конверсія</div>
                <div className="stat-number">{activePercentage}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Main;
