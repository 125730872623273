import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import UserRow from "../components/UserRow";
import {
  addSubDays,
  blockUser,
  deleteUser,
  getUsers,
  getUsersInvoice,
  addToGrop,
  removeToGrop,
  changePlan,
} from "../http/userAPI";
import { Context } from "../index";
import "./styles/Clients.css";

const Clients = observer(() => {
  const { users, userInvoice } = useContext(Context);
  const [sortBy, setSortBy] = useState("activePlan");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;

  // Helper function for subscription plan sorting
  const sortBySubscriptionPlan = (a, b, order = "desc") => {
    const planOrder = ["Platinum", "Gold", "Silver", "Bronze"];
    const aPlan = a.activePlan || "";
    const bPlan = b.activePlan || "";
    
    const aIndex = planOrder.indexOf(aPlan);
    const bIndex = planOrder.indexOf(bPlan);
    
    // If plan not found in planOrder array, it's considered lower than any defined plan
    const aValue = aIndex === -1 ? planOrder.length : aIndex;
    const bValue = bIndex === -1 ? planOrder.length : bIndex;
    
    if (order === "asc") {
      return aValue - bValue; // Ascending: lower plans first
    } else {
      return bValue - aValue; // Descending: higher plans first
    }
  };

  useEffect(() => {
    getUsers().then((data) => {
      // Sort users by subscription type from highest to lowest before setting them
      const sortedData = [...data].sort((a, b) => sortBySubscriptionPlan(a, b, "desc"));
      users.setUser(sortedData);
    });
  }, [users]);

  useEffect(() => {
    getUsersInvoice().then((data) => {
      userInvoice.setInvoices(data); // Загружаем счета и сохраняем в состояние
    });
  }, [userInvoice]);

  // Проверяем, что данные счетов загружены
  const invoices =
    userInvoice?.invoices.filter((invoice) => invoice.status === "approved") ||
    [];

  const activity =
    userInvoice?.invoices.filter(
      (invoice) =>
        invoice.status === "approved" ||
        invoice.status === "waiting_for_payment"
    ) || [];

  const handleDeleteUser = async (userId) => {
    await deleteUser(userId);
  };

  const handleAddUser = async (userId) => {
    await addToGrop(userId);
  };

  const handleRemoveUser = async (userId) => {
    await removeToGrop(userId);
  };

  const handleAddSubscription = async (userId, days) => {
    await addSubDays(userId, days);
  };

  const handleBlockUser = async (userId) => {
    await blockUser(userId, true);
  };
  const handlechangePlan = async (userId, plan) => {
    await changePlan(userId, plan);
  };

  const handleSort = (column) => {
    if (column === "activePlan") {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      setSortOrder(newSortOrder);
      setSortBy(column);
      
      // Sort users by subscription plan
      users.users.sort((a, b) => sortBySubscriptionPlan(a, b, newSortOrder));
    } else {
      if (sortBy === column) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortBy(column);
        setSortOrder("asc");
      }
    }
  };

  // Helper function for date sorting that handles empty/null values
  const sortByDate = (a, b, field, order = "asc") => {
    const dateA = a[field] ? new Date(a[field]).getTime() : 0;
    const dateB = b[field] ? new Date(b[field]).getTime() : 0;
    
    if (order === "asc") {
      // Empty dates should be at the beginning in ascending order
      if (dateA === 0 && dateB !== 0) return -1;
      if (dateA !== 0 && dateB === 0) return 1;
      return dateA - dateB;
    } else {
      // Empty dates should be at the end in descending order
      if (dateA === 0 && dateB !== 0) return 1;
      if (dateA !== 0 && dateB === 0) return -1;
      return dateB - dateA;
    }
  };

  // Helper function for numeric sorting that handles empty/null values
  const sortByNumber = (a, b, field, order = "asc") => {
    const numA = a[field] !== undefined && a[field] !== null ? Number(a[field]) : -1;
    const numB = b[field] !== undefined && b[field] !== null ? Number(b[field]) : -1;
    
    if (order === "asc") {
      // Empty values should be at the beginning in ascending order
      if (numA === -1 && numB !== -1) return -1;
      if (numA !== -1 && numB === -1) return 1;
      return numA - numB;
    } else {
      // Empty values should be at the end in descending order
      if (numA === -1 && numB !== -1) return 1;
      if (numA !== -1 && numB === -1) return -1;
      return numB - numA;
    }
  };

  const sortedUsers = [...users.users].sort((a, b) => {
    // Special handling for different column types
    if (sortBy === "activePlan") {
      return sortBySubscriptionPlan(a, b, sortOrder);
    } else if (sortBy === "birthDate" || sortBy === "subscribeEndDate") {
      return sortByDate(a, b, sortBy, sortOrder);
    } else if (sortBy === "leftDays") {
      return sortByNumber(a, b, sortBy, sortOrder);
    } else {
      // Default sorting for other columns
      const valueA = a[sortBy] || "";
      const valueB = b[sortBy] || "";
      
      if (sortOrder === "asc") {
        return valueA > valueB ? 1 : -1;
      } else {
        return valueA < valueB ? 1 : -1;
      }
    }
  });

  const getSortSymbol = (column) => {
    if (sortBy === column) {
      return sortOrder === "asc" ? "▲" : "▼";
    }
    return "";
  };

 // const indexOfLastUser = (currentPage + 1) * usersPerPage;
 // const indexOfFirstUser = indexOfLastUser - usersPerPage;
  //const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser)

  const currentUsers = sortedUsers.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  const pageNumbers = Math.ceil(sortedUsers.length / usersPerPage);
  const pageButtons = Array.from({ length: pageNumbers }, (_, i) => i);

  // Сопоставляем пользователей с их одобренными счетами

  const usersWithInvoices = currentUsers.map((user) => {
    // Находим все одобренные счета для данного пользователя
    const approvedInvoices = invoices.filter(
      (inv) => inv.telegramId === user.telegramId
    );

    // Находим все активности для данного пользователя
    const userActivities = activity.filter(
      (act) => act.telegramId === user.telegramId
    );

    return {
      ...user,
      approvedInvoices,
      userActivities,
    };
  });

  return (
    <div className="root">
      <div className="user-table">
        <Table hover page-size="4">
          <thead>
            <tr>
              <th onClick={() => handleSort("name")} style={{ width: "18vw" }}>
                Ім'я
                {getSortSymbol("name")}
              </th>
              <th
                onClick={() => handleSort("phoneNumber")}
                style={{ width: "14vw" }}
              >
                Номер телефону
                {getSortSymbol("phoneNumber")}
              </th>
              <th
                onClick={() => handleSort("birthDate")}
                style={{ width: "16vw" }}
              >
                Дата народження
                {getSortSymbol("birthDate")}
              </th>
              <th
                onClick={() => handleSort("activePlan")}
                style={{ width: "16vw" }}
              >
                Тип підписки
                {getSortSymbol("activePlan")}
              </th>
              <th
                onClick={() => handleSort("leftDays")}
                style={{ width: "14vw" }}
              >
                Тривалість підписки
                {getSortSymbol("leftDays")}
              </th>
              <th
                onClick={() => handleSort("subscribeEndDate")}
                style={{ width: "14vw" }}
              >
                Дата закінчення оплати
                {getSortSymbol("subscribeEndDate")}
              </th>
              <th style={{ width: "14vw" }}>Попередні підписки</th>

              <th style={{ width: "20vw" }}>Дії</th>
            </tr>
          </thead>

          <tbody>
            {usersWithInvoices.map((user) => (
              <UserRow
                key={user.id}
                user={user}
                onDeleteUser={handleDeleteUser}
                onAddSubscription={handleAddSubscription}
                onBlockUser={handleBlockUser}
                OnAddUser={handleAddUser}
                OnRemoveUser={handleRemoveUser}
                OnChangePlan={handlechangePlan}
                invoice={user.invoice}
              />
            ))}
          </tbody>
        </Table>
      </div>

      <div className="pagination-container">
        {pageButtons.map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={currentPage === page ? "selectedPage" : ""}
          >
            {page + 1}
          </button>
        ))}
      </div>
    </div>
  );
});

export default Clients;
