import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useRef } from "react";
import { Table, Tabs, Tab } from "react-bootstrap";

import "./styles/Clients.css";
import toast from "react-hot-toast";

import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import useNotifications from "../components/hooks/useNotifications";
import NotificationsRow from "../components/NotificationsRow";
import {
  postNotification,
  updateNotification,
} from "../http/notificationController";
import { useQueryClient } from "@tanstack/react-query";
import { ITEMS_PER_PAGE_COUNT } from "../utils/consts";
import usePagination from "../components/hooks/usePagination";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { $authHost } from "../http";
import { isValidImageUrl, uploadImageToImbb } from "../utils/imbbUploader";

const Notifications = observer(() => {
  const { page, handlePageChange } = usePagination();
  const { data, isLoading } = useNotifications(page);
  const [activeTab, setActiveTab] = useState("notifications");
  const fileInputRef = useRef(null);

  // Стани для звичайних сповіщень
  const [markDown, setMarkDown] = useState("");
  const [previewMarkDown, setPreviewMarkDown] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [notificationToEdit, setNotificationToEdit] = useState({
    id: "",
    text: "",
    photo: "",
    link: "",
  });
  const [photo, setPhoto] = useState("");
  const [link, setLink] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const queryClient = useQueryClient();

  // Стани для push-повідомлень
  const [pushTitle, setPushTitle] = useState("");
  const [pushMessage, setPushMessage] = useState("");
  const [isLoadingPush, setIsLoadingPush] = useState(false);
  const [pushResponse, setPushResponse] = useState(null);

  // Поточна дата для Preview
  const currentDate = new Date().toLocaleString('uk-UA', {
    hour: '2-digit',
    minute: '2-digit'
  });

  // Ефект для оновлення стану при зміні notificationToEdit
  useEffect(() => {
    if (notificationToEdit && notificationToEdit.id) {
      setMarkDown(notificationToEdit.text || "");
      setPreviewMarkDown(
        (notificationToEdit.text || "")
          .replace(/__(.*?)__/g, "*$1*")
          .replace(/\*(.*?)\*/g, "**$1**")
      );
      setPhoto(notificationToEdit.photo || "");
      setLink(notificationToEdit.link || "");
    }
  }, [notificationToEdit]);

  const resetState = () => {
    setMarkDown("");
    setPreviewMarkDown("");
    setPhoto("");
    setLink("");
  };

  const resetPushState = () => {
    setPushTitle("");
    setPushMessage("");
    setPushResponse(null);
  };

  const handleFormChange = (e, name) => {
    const value = e.target.value;
    if (name === "photo") {
      setPhoto(value);
    } else if (name === "link") {
      setLink(value);
    }
  };

  const handlePushFormChange = (e, field) => {
    if (field === "title") {
      setPushTitle(e.target.value);
    } else if (field === "message") {
      setPushMessage(e.target.value);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const imageUrl = await uploadImageToImbb(file);
      if (imageUrl) {
        setPhoto(imageUrl);
      }
    } catch (error) {
      console.error("Помилка завантаження файлу:", error);
      toast.error("Не вдалося завантажити зображення");
    } finally {
      setIsUploading(false);
    }
  };

  const handleAddNotification = async (e) => {
    e.preventDefault();

    const newNotification = {
      text: markDown,
      photo,
      link,
    };

    try {
      await postNotification(newNotification);
      await queryClient.invalidateQueries(["notifications", page]);
      resetState();
      toast.success("Сповіщення успішно надіслано!");
      setShowModal(false);
    } catch (error) {
      console.log("Error sending notification:", error);
      toast.error("Помилка надсилання сповіщення. Спробуйте пізніше");
    }
  };

  const handleSendPushBroadcast = async (e) => {
    e.preventDefault();
    setIsLoadingPush(true);
    setPushResponse(null);

    try {
      const { data } = await $authHost.post("api/push/broadcast", {
        title: pushTitle,
        message: pushMessage,
      });
      
      setPushResponse(data);
      toast.success("Push-сповіщення успішно надіслано!");
    } catch (error) {
      console.error("Error sending push broadcast:", error);
      toast.error("Помилка надсилання push-сповіщення!");
      setPushResponse({
        status: "error",
        message: error.response?.data?.message || "Сталася помилка при надсиланні",
      });
    } finally {
      setIsLoadingPush(false);
    }
  };

  const handleEditNotification = async (e) => {
    e.preventDefault();

    if (!notificationToEdit.id) {
      toast.error("Помилка: ID сповіщення відсутній");
      return;
    }

    const updatedNotification = {
      text: markDown,
      photo,
      link,
    };

    console.log("Оновлюю сповіщення:", {
      id: notificationToEdit.id,
      data: updatedNotification
    });

    try {
      await updateNotification(notificationToEdit.id, updatedNotification);
      await queryClient.invalidateQueries(["notifications", page]);
      resetState();
      toast.success("Сповіщення успішно оновлено!");
      setShowModal(false);
      setIsEditModal(false);
    } catch (error) {
      console.error("Error updating notification:", error);
      toast.error("Помилка оновлення сповіщення. Спробуйте пізніше");
    }
  };

  const handleMarkdown = (e) => {
    const value = e.target.value;
    setMarkDown(value);
    // Обробляємо markdown для відображення без зайвих переносів рядків
    // Замінюємо подвійні переноси рядків на спеціальний маркер, щоб зберегти їх
    const processedValue = value
      .replace(/\n\n/g, '\n&nbsp;\n')  // Зберігаємо подвійні переноси
      .replace(/__(.*?)__/g, "*$1*")
      .replace(/\*(.*?)\*/g, "**$1**");
    
    setPreviewMarkDown(processedValue);
  };

  const handleOpenEditModal = (notification) => {
    setNotificationToEdit(notification);
    setIsEditModal(true);
    setShowModal(true);
  };

  return (
    <div className="root">
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="notifications-tabs"
        id="notification-tabs"
      >
        <Tab eventKey="notifications" title="Сповіщення для бота">
          {!showModal ? (
            <>
              <div className="header">
                <button
                  className="action-btn primary"
                  onClick={() => {
                    setShowModal(true);
                    setIsEditModal(false);
                    resetState();
                  }}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="20" 
                    height="20" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  Додати сповіщення
                </button>
              </div>
              <div className="user-table">
                <Table hover>
                  <thead>
                    <tr>
                      <th style={{ width: "42vw" }}>Текст</th>
                      <th style={{ width: "13vw" }}>Фото</th>
                      <th style={{ width: "13vw" }}>Посилання</th>
                      <th style={{ width: "13vw" }}>Створено</th>
                      <th style={{ width: "13vw" }}>Дії</th>
                    </tr>
                  </thead>

                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={5}>Завантаження...</td>
                      </tr>
                    ) : !data?.notifications?.length ? (
                      <tr>
                        <td colSpan={5}>Сповіщень немає</td>
                      </tr>
                    ) : (
                      data?.notifications.map((notification) => (
                        <NotificationsRow
                          key={notification.id}
                          notification={notification}
                          onEdit={handleOpenEditModal}
                        />
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
              {data?.totalPages > 1 && (
                <Pagination
                  className="pagination"
                  current={page}
                  total={data?.totalItems}
                  pageSize={ITEMS_PER_PAGE_COUNT}
                  onChange={handlePageChange}
                />
              )}
            </>
          ) : (
            <div className="notify-form">
              <form
                onSubmit={isEditModal ? handleEditNotification : handleAddNotification}
              >
                <div className="input_block">
                  <p>Текст</p>
                  <textarea
                    value={markDown}
                    onChange={handleMarkdown}
                    placeholder="Введіть текст: *жирний*, _курсив_, натиснути Enter - перенос рядка"
                    rows={20}
                    cols={50}
                  />
                </div>
                <div className="input_block">
                  <p>Фото</p>
                  <div className="file-upload-container">
                    <input
                      type="text"
                      placeholder="Посилання на фото"
                      onChange={(e) => handleFormChange(e, "photo")}
                      value={photo}
                      className="photo-url-input"
                    />
                    <div className="file-upload-actions">
                      <button 
                        type="button" 
                        className="action-btn"
                        onClick={() => fileInputRef.current.click()}
                        disabled={isUploading}
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="18" 
                          height="18" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2" 
                          strokeLinecap="round" 
                          strokeLinejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="17 8 12 3 7 8"></polyline>
                          <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        {isUploading ? "Завантаження..." : "Завантажити фото"}
                      </button>
                      {photo && (
                        <button 
                          type="button" 
                          className="action-btn"
                          onClick={() => setPhoto("")}
                        >
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="18" 
                            height="18" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            strokeWidth="2" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                          Очистити
                        </button>
                      )}
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
                <div className="input_block">
                  <p>Посилання</p>
                  <input
                    type="text"
                    placeholder="Посилання на статтю/веб-сайт"
                    onChange={(e) => handleFormChange(e, "link")}
                    value={link}
                  />
                </div>

                <div className="form-actions">
                  <button type="submit" className="action-btn primary">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                    {isEditModal ? "Оновити сповіщення" : "Надіслати сповіщення"}
                  </button>
                  <button
                    type="button"
                    className="action-btn"
                    onClick={() => {
                      setShowModal(false);
                      resetState();
                      setIsEditModal(false);
                    }}
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                    Скасувати
                  </button>
                </div>
              </form>
              
              <div className="preview">
                <div className="telegram-preview">
                  <div className="telegram-preview-header">
                    Перегляд повідомлення
                  </div>
                  <div className="telegram-message">
                    <div className="telegram-avatar">
                      <div className="bot-avatar">FC</div>
                    </div>
                    <div className="telegram-content">
                      <div className="telegram-username">FC Мрія</div>
                      <div className="telegram-text">
                        <ReactMarkdown 
                          remarkPlugins={[remarkBreaks]} 
                          components={{
                            p: ({node, ...props}) => <div {...props} style={{marginBottom: '0.5em'}} />,
                            br: () => <br style={{display: 'block', margin: '0.3em 0'}} />
                          }}
                        >
                          {previewMarkDown}
                        </ReactMarkdown>
                      </div>
                      {photo && isValidImageUrl(photo) && (
                        <div className="telegram-image">
                          <img src={photo} alt="Зображення до повідомлення" />
                        </div>
                      )}
                      {photo && !isValidImageUrl(photo) && (
                        <div className="telegram-image-placeholder">
                          <em>[Фото буде відображатись тут]</em>
                        </div>
                      )}
                      {link && (
                        <a href={link} className="telegram-link" target="_blank" rel="noopener noreferrer">
                          {link}
                        </a>
                      )}
                      <div className="telegram-date">{currentDate}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Tab>

        <Tab eventKey="push" title="Push-повідомлення">
          <div className="push-notifications-container">
            <div className="push-form-container">
              <h3>Масова розсилка Push-повідомлень</h3>
              <p className="push-description">
                Відправте важливе сповіщення всім користувачам мобільного додатку
              </p>
              
              <form onSubmit={handleSendPushBroadcast} className="push-form">
                <div className="input_block">
                  <p>Заголовок повідомлення</p>
                  <input
                    type="text"
                    placeholder="Наприклад: Оновлення додатку"
                    value={pushTitle}
                    onChange={(e) => handlePushFormChange(e, "title")}
                    required
                  />
                </div>
                
                <div className="input_block">
                  <p>Текст повідомлення</p>
                  <textarea
                    placeholder="Наприклад: Ми випустили нову версію додатку з покращеною швидкодією та новими функціями!"
                    value={pushMessage}
                    onChange={(e) => handlePushFormChange(e, "message")}
                    rows={6}
                    required
                  />
                </div>
                
                <div className="form-actions">
                  <button 
                    type="submit" 
                    disabled={isLoadingPush}
                    className="action-btn primary"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M22 2L11 13"></path>
                      <path d="M22 2L15 22L11 13L2 9L22 2Z"></path>
                    </svg>
                    {isLoadingPush ? "Надсилання..." : "Надіслати Push-повідомлення"}
                  </button>
                  
                  <button
                    type="button"
                    onClick={resetPushState}
                    className="action-btn"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M3 3v18h18"></path>
                      <path d="M3 12h18"></path>
                      <path d="M12 3v18"></path>
                    </svg>
                    Очистити форму
                  </button>
                </div>
              </form>
            </div>
            
            <div className="push-preview-container">
              <h3>Попередній перегляд</h3>
              <div className="push-notification-preview">
                <div className="push-preview-header">
                  <div className="push-app-icon"></div>
                  <div className="push-app-name">FC Мрія</div>
                  <div className="push-time">Зараз</div>
                </div>
                <div className="push-preview-content">
                  <div className="push-preview-title">{pushTitle || "Заголовок повідомлення"}</div>
                  <div className="push-preview-message">{pushMessage || "Текст вашого повідомлення з'явиться тут"}</div>
                </div>
              </div>
              
              {pushResponse && (
                <div className={`push-response ${pushResponse.status === 'error' ? 'push-response-error' : 'push-response-success'}`}>
                  <h4>{pushResponse.status === 'error' ? 'Помилка надсилання' : 'Статистика розсилки'}</h4>
                  {pushResponse.status === 'error' ? (
                    <p>{pushResponse.message}</p>
                  ) : (
                    <div className="push-stats">
                      <div className="push-stat-item">
                        <span>Успішно надіслано:</span>
                        <strong>{pushResponse.successCount}</strong>
                      </div>
                      <div className="push-stat-item">
                        <span>Помилки:</span>
                        <strong>{pushResponse.failureCount}</strong>
                      </div>
                      <div className="push-stat-item">
                        <span>Всього отримувачів:</span>
                        <strong>{pushResponse.totalRecipients}</strong>
                      </div>
                    </div>
                  )}
                </div>
              )}
              
              <div className="push-examples">
                <h4>Приклади повідомлень</h4>
                <div className="push-example-item" onClick={() => {
                  setPushTitle('Оновлення додатку');
                  setPushMessage('Ми випустили нову версію додатку з покращеною швидкодією та новими функціями!');
                }}>
                  <strong>Оновлення додатку</strong>
                  <p>Ми випустили нову версію додатку з покращеною швидкодією та новими функціями!</p>
                </div>
                <div className="push-example-item" onClick={() => {
                  setPushTitle('Важлива інформація');
                  setPushMessage('Завтра з 10:00 до 12:00 сервіс буде недоступний через технічні роботи.');
                }}>
                  <strong>Важлива інформація</strong>
                  <p>Завтра з 10:00 до 12:00 сервіс буде недоступний через технічні роботи.</p>
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
});

export default Notifications;
