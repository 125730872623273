import React from "react";
import { useState, useEffect } from "react";
import { getChannelMembers, getUsers, getUsersInvoice } from "../http/userAPI";
import { observer } from "mobx-react-lite";
import "./styles/Main.css";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

const Main = observer(() => {
  const [users, setUsers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [channelMembers, setChannelMembers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedUsers = await getUsers();
      const fetchedInvoices = await getUsersInvoice();
      const fetchedChannelMembers = await getChannelMembers();
      setChannelMembers(fetchedChannelMembers);
      setUsers(fetchedUsers);
      setInvoices(fetchedInvoices);
    };
    fetchData();
  }, []);

  const activeSubscriptions = users.filter((user) => user.isSubscribe);

  const totalAmount = users.reduce((acc, user) => acc + user.totalAmount, 0);

  // Calculate total amounts for the current month
  const calculateTotalForCurrentMonth = (invoices) => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth(); // Months are 0-indexed, January is 0

    const startDate = new Date(currentYear, currentMonth, 1);
    const endDate = new Date(currentYear, currentMonth + 1, 0); // Last day of the month

    console.log(`Calculating from ${startDate} to ${endDate}`); // Log the date range

    const filteredInvoices = invoices.filter((invoice) => {
      const paidDate = new Date(invoice.paidDate);
      const isWithinMonth = paidDate >= startDate && paidDate <= endDate;
      console.log(`Checking invoice with date ${paidDate}: ${isWithinMonth}`); // Log each check
      return invoice.status === "approved" && isWithinMonth;
    });

    console.log(`Filtered Invoices: ${filteredInvoices.length}`); // How many passed the filter

    const totalAmount = filteredInvoices.reduce((acc, invoice) => {
      const amount =
        typeof invoice.amount === "string"
          ? parseInt(invoice.amount, 10)
          : invoice.amount; // Convert to number if string
      return acc + amount;
    }, 0);

    console.log(`Total Amount for Current Month: ${totalAmount}`); // Log the calculated total
    return totalAmount;
  };

  const totalAmountCurrentMonth = calculateTotalForCurrentMonth(invoices);

  console.log(`users: ${users}`);

  ChartJS.register(ArcElement, Tooltip, Legend);

  const subscriptionData = {
    labels: [
      // "Тестовий період",
      "Місяць",
      "Три місяці",
      "Пів року",
      //  "Видана адміном",
      "Один рік",
    ],
    datasets: [
      {
        label: "Subscriptions",
        data: [
          //users.filter((user) => user.regularMode === "test").length,
          users.filter((user) => user.subDuration === "1month").length,
          users.filter((user) => user.subDuration === "3month").length,
          users.filter((user) => user.subDuration === "6month").length,
          // users.filter((user) => user.regularMode === "custom").length,
          users.filter((user) => user.subDuration === "12month").length,
        ],
        backgroundColor: [
          "#51E8F2",
          "#51F288",
          "#F2BB51",
          "#F25151",
          "#5161F2",
          "#777777",
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
        labels: {
          generateLabels: function (chart) {
            const data = chart.data;
            const labels = data.labels;
            const datasets = data.datasets;
            const total = datasets[0].data.reduce(function (a, b) {
              return a + b;
            }, 0);
            let legendItems = [];
            for (let i = 0; i < datasets[0].data.length; i++) {
              legendItems.push({
                text:
                  labels[i] +
                  " - " +
                  datasets[0].data[i] +
                  " (" +
                  ((datasets[0].data[i] / total) * 100).toFixed(2) +
                  "%)",
                fillStyle: datasets[0].backgroundColor[i],
                hidden: isNaN(datasets[0].data[i]),
              });
            }
            return legendItems;
          },
        },
      },
    },
  };

  const testPeriodData = {
    labels: ["Усього користувачів", "Пройшли тестовий період"],
    datasets: [
      {
        label: "testPeriod",
        data: [
          users.length,
          users.filter((user) => user.isWasTestPeriod).length,
        ],
        backgroundColor: ["#51E8F2", "#5161F2"],
      },
    ],
  };

  return (
    <div className="dashboard">
      <div className="textsBlock">
        <div>
          <p className="textBlockName">Користувачів у боті</p>
          <p className="textBlockValue">{users.length}</p>
        </div>
        <div>
          <p className="textBlockName">Користувачів у каналі</p>
          <p className="textBlockValue">{channelMembers}</p>
        </div>
        <div>
          <p className="textBlockName">Активних підписників</p>
          <p className="textBlockValue">{activeSubscriptions.length}</p>
        </div>
        <div>
          <p className="textBlockName">Всього зароблено</p>
          <p className="textBlockValue">
            {totalAmount}
            <span style={{ fontSize: "24px" }}> грн</span>
          </p>
        </div>

        <div>
          <p className="textBlockName">Всього за місяць</p>
          <p className="textBlockValue">
            {totalAmountCurrentMonth}
            <span style={{ fontSize: "24px" }}> грн</span>
          </p>
        </div>
      </div>
      <div className="diagramsBlock">
        <div className="diagramBlock">
          <div className="labels">
            <span className="labelsName">Типи підписок</span>
            {subscriptionData.labels.map((label, index) => (
              <div className="label" key={index}>
                <div className="sideInfoLabel">
                  <div
                    className="color"
                    style={{
                      backgroundColor:
                        subscriptionData.datasets[0].backgroundColor[index],
                    }}
                  ></div>
                  <div className="text">{label}</div>
                </div>
                <div className="sideInfoLabel">
                  <div className="value">
                    {subscriptionData.datasets[0].data[index]}
                  </div>
                  <div className="percentage">
                    (
                    {(
                      (subscriptionData.datasets[0].data[index] /
                        subscriptionData.datasets[0].data.reduce(
                          (a, b) => a + b,
                          0
                        )) *
                      100
                    ).toFixed(2)}
                    %)
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="diagram">
            <Doughnut data={subscriptionData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default Main;
