import { $authHost } from ".";

export const getYoutube = async () => {
  try {
    const { data } = await $authHost.get("api/youtube");
    return data;
  } catch (error) {
    console.log("Error geting youtube: ", error);
  }
};
export const updateYoutube = async (updatedItem) => {
  try {
    const { data } = await $authHost.put("api/youtube", updatedItem);
    return data;
  } catch (error) {
    console.log("Error updating youtube: ", error);
  }
};
