import { useQuery } from "@tanstack/react-query";
import { getAllPackages } from "../../http/packageController";

const usePackages = () => {
  return useQuery({
    queryKey: ["packages"],
    queryFn: getAllPackages,
  });
};

export default usePackages;
