import { useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles/UserRow.css";
import toast from "react-hot-toast";
import { shortenImageUrl } from "../utils/imageShortener";
import { deleteNotification } from "../http/notificationController";
import { useQueryClient } from "@tanstack/react-query";

const NotificationsRow = ({
  notification,
  onEdit,
}) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  const handleDeleteNotification = async (notificationId) => {
    try {
      const result = await deleteNotification(notificationId);

      if (result) {
        toast.success("Сповіщення успішно видалено!");
      } else {
        toast.error("Щось пішло не так. Спробуйте пізніше!");
      }
      queryClient.invalidateQueries(["notifications"]);
    } catch (error) {
      console.error("Error deleting notification:", error);
      toast.error("Помилка видалення сповіщення!");
    }
  };

  const handleEdit = () => {
    onEdit(notification);
  };

  return (
    <tr key={notification.id}>
      <td>{notification.text || "--"}</td>
      <td>{shortenImageUrl(notification.photo) || "--"}</td>
      <td>{notification.link || "--"}</td>
      <td>{new Date(notification.createdAt).toLocaleString('uk-UA') || "--"}</td>
      <td>
        <div className="actionBlock">
          <button
            onClick={handleEdit}
            className="action-btn"
            title="Редагувати сповіщення"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              className="edit-icon"
            >
              <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
              <path d="m15 5 4 4"></path>
            </svg>
          </button>
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="action-btn danger"
            title="Видалити сповіщення"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              className="delete-icon"
            >
              <path d="M3 6h18"></path>
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </button>
        </div>
      </td>
      <Modal
        className="modalWindow"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size={"md"}
      >
        <Modal.Header>Точно бажаєте видалити сповіщення?</Modal.Header>
        <Modal.Body>
          <div className="modal-actions">
            <button
              className="action-btn danger"
              onClick={async () => {
                await handleDeleteNotification(notification.id);
                setShowModal(false);
              }}
            >
              Видалити
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="action-btn"
            >
              Скасувати
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </tr>
  );
};

export default NotificationsRow;
