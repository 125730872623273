import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles/UserRow.css";
import { Pencil } from "lucide-react";
import { deleteServiceById, updateService } from "../http/serviceController";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { isNumeric } from "validator";

const ServicesRow = ({ service }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    price: 0,
    url: "",
  });

  const resetState = () => {
    setFormValues({
      name: "",
      description: "",
      price: "",
      url: "",
    });
  };

  const handleFormChange = (e, name) => {
    const value = e.target.value;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeleteService = async (serviceId) => {
    const result = await deleteServiceById(serviceId);

    if (result) {
      toast.success("Послуга успішно видалена!");
    } else {
      toast.error("Щось пішло не так. Спробуйте пізніше!");
    }
    queryClient.invalidateQueries("services");
    setShowModal(false);
  };

  const handleEditService = async (serviceId) => {
    if (!isNumeric(formValues.price) || parseFloat(formValues.price) <= 0) {
      toast.error("Некоректне значення ціни!");
      return;
    }
    const updatedService = {
      name: formValues.name,
      description: formValues.description,
      price: Number(formValues.price),
      url: formValues.url,
    };
    const result = await updateService(serviceId, updatedService);

    if (result) {
      toast.success("Послуга успішно змінена!");
    } else {
      toast.error("Щось пішло не так. Спробуйте пізніше!");
    }

    queryClient.invalidateQueries("services");
    setShowModal(false);
    resetState();
  };

  useEffect(() => {
    setFormValues({
      name: service.name,
      description: service.description,
      price: service.price,
      url: service.url,
    });
  }, [service]);

  return (
    <tr key={service.id}>
      <td>{service.name || "--"}</td>
      <td>{service.description || "--"}</td>
      <td>{service.url || "--"}</td>
      <td>{service.price || "--"}</td>
      <td>
        <div className="actionBlock">
          <button
            onClick={() => {
              setShowModal(true);
              setSelectedAction("editService");
            }}
            className="blockUserBtn"
          >
            <Pencil alt="Edit service" title="Edit service" />
          </button>
          <button
            onClick={() => {
              setShowModal(true);
              setSelectedAction("deleteService");
            }}
            className="deleteUserBtn"
          >
            <img
              src="/clientsImg/trash.svg"
              alt="Delete service"
              title="Delete service"
            />
          </button>
        </div>
      </td>
      <Modal
        className="modalWindow"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size={"md"}
      >
        <Modal.Header>
          {selectedAction === "addService"
            ? "Додати послугу"
            : selectedAction === "editService"
            ? "Редагувати послугу"
            : selectedAction === "deleteService"
            ? "Точно бажаєте видалити послугу?"
            : ""}
        </Modal.Header>

        {selectedAction === "editService" && (
          <Modal.Body className="modal-body">
            <div className="input_block">
              <p>Назва послуги</p>
              <input
                type="text"
                placeholder="Назва"
                value={formValues.name}
                onChange={(e) => handleFormChange(e, "name")}
              />
            </div>
            <div className="input_block">
              <p>Опис послуги</p>
              <textarea
                type="text"
                placeholder="Опис"
                onChange={(e) => handleFormChange(e, "description")}
                value={formValues.description}
              />
            </div>
            {formValues.url && (
              <div
                className="image-preview"
                style={{ backgroundImage: `url(${formValues.url})` }}
              />
            )}
            <div className="input_block">
              <p>Фото (посилання)</p>
              <input
                type="text"
                placeholder="Фото"
                value={formValues.url}
                onChange={(e) => handleFormChange(e, "url")}
              />
            </div>
            <div className="input_block">
              <p>Ціна послуги</p>
              <input
                type="text"
                placeholder="Ціна"
                value={formValues.price}
                onChange={(e) => handleFormChange(e, "price")}
              />
            </div>
          </Modal.Body>
        )}

        <Modal.Footer>
          <>
            <button
              className="black-close-button"
              onClick={async () => {
                if (selectedAction === "deleteService") {
                  await handleDeleteService(service.id);
                } else if (selectedAction === "editService") {
                  await handleEditService(service.id, {
                    name: formValues.name,
                    description: formValues.description,
                    price: formValues.price,
                  });
                }
              }}
            >
              {selectedAction === "addService"
                ? "Додати послугу"
                : selectedAction === "editService"
                ? "Зберегти зміни"
                : selectedAction === "deleteService"
                ? "Видалити"
                : "Закрити"}
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="red-close-button"
            >
              Закрити
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

export default ServicesRow;
