import "./styles/UserRow.css";
import { Pencil } from "lucide-react";

const packageTypes = {
  bronze: "Bronze 🥉",
  silver: "Silver 🥈",
  gold: "Gold 🥇",
  platinum: "Platinum 💎",
  diamond: "Diamond 💠",
};

const PackagesRow = ({
  item,
  setIsEditModal,
  setPackage,
  setShowEditModal,
}) => {
  const displayType = packageTypes[item.type] || item.type || "--";

  return (
    <tr key={item.id}>
      <td>{item.text || "--"}</td>
      <td>{item.url || "--"}</td>
      <td>{displayType}</td>
      <td>
        <div className="actionBlock">
          <button
            onClick={() => {
              setShowEditModal(true);
              setPackage({
                id: item.id,
                text: item.text,
                type: item.type,
                url: item.url,
              });
            }}
            className="blockUserBtn"
          >
            <Pencil alt="Edit package" title="Edit package" />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default PackagesRow;
