import { useQuery } from "@tanstack/react-query";
import { getCalendar } from "../../http/calendarController";

const useCalendar = () => {
  return useQuery({
    queryKey: ["calendar"],
    queryFn: getCalendar,
  });
};

export default useCalendar;
