import React from "react";
import "./styles/Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer-root">
      <div className="footer-content">
        <div className="footer-club">
          <span className="footer-fc">FC</span>
          <span className="footer-mriya">Мрія</span>
        </div>
        
        <div className="footer-divider"></div>
        
        <div className="footer-info">
          <span className="footer-powered">Powered by</span>
          <span className="footer-company">DAROBOTS</span>
          <span className="footer-copyright">© {currentYear} Адмін-панель</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
