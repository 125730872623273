import { $host, $authHost } from "./index";
import jwt_decode from "jwt-decode"

export const login = async (username, password) => {
    const { data } = await $host.post('api/admin/login', { login: username, password })
    localStorage.setItem('token', data.data.token)
    localStorage.setItem('adminUsername', username)
    return jwt_decode(data.data.token)
}


export const check = async () => {
    const token = localStorage.getItem('token');  // Получаем токен из localStorage
    if (!token) {
        console.error("No token available.");
        throw new Error("No token available");
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`  // Формирование заголовка Authorization
        }
    };

    try {
        // Отправка GET запроса с токеном в заголовке Authorization
        const { data } = await $authHost.get('api/admin/auth', config);
        console.log("Data received from check:", data);
        return data;
    } catch (error) {
        console.error("Failed to fetch data:", error);
        throw error;  // Переброс ошибки для дальнейшей обработки
    }
}

