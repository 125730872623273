import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getAllServices } from "../../http/serviceController";

const useServices = (page) => {
  return useQuery({
    queryKey: ["services", page],
    queryFn: () => getAllServices(page),
    placeholderData: keepPreviousData,
  });
};

export default useServices;
