import { makeAutoObservable } from 'mobx';

class UserInvoiceStore {
  invoices = [];

  constructor() {
    makeAutoObservable(this);
  }

  setInvoices(invoices) {
    this.invoices = invoices;
  }

  getInvoices() {
    return this.invoices;
  }
}

export default UserInvoiceStore;
