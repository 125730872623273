import { $authHost } from ".";

export const getNotifications = async (page) => {
  try {
    const { data } = await $authHost.get(`api/notifications?page=${page}`);
    return data;
  } catch (error) {
    console.log("Error geting notifications: ", error);
  }
};
export const postNotification = async (newNotification) => {
  try {
    const { data } = await $authHost.post("api/notifications", newNotification);
    return data;
  } catch (error) {
    console.log("Error creating notification: ", error);
  }
};
export const updateNotification = async (id, newNotification) => {
  try {
    const { data } = await $authHost.put(
      `api/notifications/${id}`,
      newNotification
    );
    return data;
  } catch (error) {
    console.log(`Error updating notification ${id}: `, error);
  }
};
export const deleteNotification = async (id) => {
  try {
    const { data } = await $authHost.delete(`api/notifications/${id}`);
    return data;
  } catch (error) {
    console.log(`Error deleting notification ${id}: `, error);
  }
};
