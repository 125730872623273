import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Pencil } from "lucide-react";
import "./styles/Clients.css";
import remarkBreaks from "remark-breaks";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import {
  getAboutButton,
  getFaqButton,
  getMissionButton,
  getSponsorsButton,
  getTeamButton,
  getSocialButton,
  putAboutButton,
  putFaqButton,
  putMissionButton,
  putSponsorsButton,
  putTeamButton,
  putSocialButton,
} from "../http/buttonsController";
import useCalendar from "../components/hooks/useCalendar";
import useYoutube from "../components/hooks/useYoutube";
import { updateYoutube } from "../http/youtubeController";
import { updateCalendar } from "../http/calendarController";
import { shortenImageUrl } from "../utils/imageShortener";

const buttonTypes = {
  aboutClubMessage: "Про клуб ⚽",
  sponsorsMessage: "Спонсорам 💰",
  teamMessage: "Наша команда 👥",
  faqMessage: "FAQ ❓",
  missionMessage: "Наша місія 🎯",
  youtube: "Youtube підбірка 🎥",
  calendar: "Календар подій 📅",
  socialMessage: "Ми в соцмережах 🌐",
};

const Buttons = () => {
  const calendarQuery = useCalendar();
  const youtubeQuery = useYoutube();

  const [youtubeButton, setYoutubeButton] = useState(null);
  const [calendarButton, setCalendarButton] = useState(null);

  const [socialButton, setSocialButton] = useState(null);


  const [aboutButton, setAboutButton] = useState(null);
  const [faqButton, setFaqButton] = useState(null);
  const [missionButton, setMimissionButton] = useState(null);
  const [teamButton, setTeamButton] = useState(null);
  const [sponsorsButton, setSponsorsButton] = useState(null);
  const [markDown, setMarkDown] = useState("");
  const [previewMarkDown, setPreviewMarkDown] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buttonToEdit, setButtonToEdit] = useState({
    key: "",
    text: "",
    url: "",
  });
  const [url, setUrl] = useState("");

  const handleMarkdown = (e) => {
    const value = e.target.value;
    setMarkDown(value);
    setPreviewMarkDown(
      value.replace(/__(.*?)__/g, "*$1*").replace(/\*(.*?)\*/g, "**$1**")
    );
  };

  const resetState = () => {
    setMarkDown("");
    setPreviewMarkDown("");
    setUrl("");
  };

  const handleEditButton = async (e) => {
    e.preventDefault();

    let buttonController;

    switch (buttonToEdit.key) {
      case "aboutClubMessage":
        buttonController = putAboutButton;
        break;
      case "sponsorsMessage":
        buttonController = putSponsorsButton;
        break;
      case "teamMessage":
        buttonController = putTeamButton;
        break;
      case "faqMessage":
        buttonController = putFaqButton;
        break;
      case "youtube":
        buttonController = updateYoutube;
        break;
      case "calendar":
        buttonController = updateCalendar;
        break;
      case "socialMessage":
        buttonController = putSocialButton;
        break;
      default:
        buttonController = putMissionButton;
    }

    const updatedButton = {
      text: markDown,
      url,
    };

    const result = await buttonController(updatedButton);

    if (result) {
      toast.success("Кнопку успішно змінено!");
    } else {
      toast.error("Щось пішло не так. Спробуйте пізніше!");
    }
    window.location.reload(true);
  };

  useEffect(() => {
    const fetchButtons = async () => {
      const aboutButton = await getAboutButton();
      const faqButton = await getFaqButton();
      const missionButton = await getMissionButton();
      const teamButton = await getTeamButton();
      const sponsorsButton = await getSponsorsButton();
      const socialButton = await getSocialButton();

      setAboutButton(aboutButton);
      setFaqButton(faqButton);
      setTeamButton(teamButton);
      setMimissionButton(missionButton);
      setSponsorsButton(sponsorsButton);
      setSocialButton(socialButton);
    };

    fetchButtons();
  }, []);

  useEffect(() => {
    if (!calendarQuery.isLoading && !youtubeQuery.isLoading) {
      setCalendarButton({
        key: "calendar",
        text: calendarQuery.data.message.text,
        url: calendarQuery.data.message.url,
      });
      setYoutubeButton({
        key: "youtube",
        text: youtubeQuery.data.message.text,
        url: youtubeQuery.data.message.url,
      });
    }
  }, [calendarQuery.data, youtubeQuery.data]);

  useEffect(() => {
    if (buttonToEdit && buttonToEdit.text) {
      setPreviewMarkDown(
        buttonToEdit.text
          .replace(/__(.*?)__/g, "*$1*")
          .replace(/\*(.*?)\*/g, "**$1**")
      );
      setMarkDown(buttonToEdit.text);
    }

    setUrl(buttonToEdit.url);
  }, [buttonToEdit]);

  if (aboutButton === null) return null;

  const formatButtonType = (key) => {
    return buttonTypes[key] || key;
  };

  return (
    <div className="root">
      {!showModal ? (
        <>
          <div className="user-table">
            <Table hover page-size="4">
              <thead>
                <tr>
                  <th style={{ width: "35vw" }}>Текст кнопки</th>
                  <th style={{ width: "15vw" }}>Фото (посилання)</th>
                  <th style={{ width: "15vw" }}>Тип кнопки</th>
                  <th style={{ width: "10vw" }}>Дії</th>
                </tr>
              </thead>

              <tbody>
                {[
                  aboutButton,
                  sponsorsButton,
                  teamButton,
                  faqButton,
                  missionButton,
                  calendarButton,
                  youtubeButton,
                  socialButton,
                ].map((button) => (
                  <tr
                    key={button?.message?.key || button?.key || Math.random()}
                  >
                    <td>{button?.message?.text || button?.text || "--"}</td>
                    <td>
                      {shortenImageUrl(button?.message?.url) ||
                        shortenImageUrl(button?.url) ||
                        "--"}
                    </td>
                    <td>
                      {formatButtonType(button?.message?.key || button?.key)}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setShowModal(true);
                          setButtonToEdit({
                            key: button?.message?.key || button?.key,
                            text: button?.message?.text || button?.text,
                            url: button?.message?.url || button?.url,
                          });
                        }}
                        className="blockUserBtn"
                      >
                        <Pencil alt="Edit button" title="Edit button" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <div className="notify-form">
          <form onSubmit={handleEditButton}>
            <div className="input_block">
              <p>Текст</p>
              <textarea
                value={markDown}
                onChange={handleMarkdown}
                placeholder="Введіть текст: *жирний*, _курсив_, натиснути Enter - перенос рядка, [Перейти на Google](https://www.google.com)"
                rows={20}
                cols={50}
              />
            </div>
            {url && (
              <div
                className="image-preview"
                style={{ backgroundImage: `url(${url})` }}
              />
            )}
            <div className="input_block">
              <p>Посилання на фото</p>
              <input
                type="text"
                placeholder="Посилання на фото"
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
            </div>
            <button type="submit">Зберегти</button>
            <button
              type="button"
              onClick={() => {
                setShowModal(false);
                resetState();
              }}
            >
              Скасувати
            </button>
          </form>
          <div className="preview">
            <p>Текст preview:</p>
            <ReactMarkdown remarkPlugins={[remarkBreaks]}>
              {previewMarkDown}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default Buttons;
