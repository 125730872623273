export const MAIN_ROUTE = "/";
export const CLIENTS_ROUTE = "/clients";
export const SERVICES_ROUTE = "/services";
export const NOTIFICATION_ROUTE = "/notifications";
export const LOGIN_ROUTE = "/login";
export const CONSULTATION_ROUTE = "/consultation";
export const BUTTONS_ROUTE = "/buttons";
export const PACKAGES_ROUTE = "/packages";

export const ITEMS_PER_PAGE_COUNT = 10;

export const PackageTypes = {
  SILVER: "silver",
  BRONZE: "bronze",
  GOLD: "gold",
  PLATINUM: "platinum",
};
