import React, { useState, useRef } from "react";
import { Modal, Table } from "react-bootstrap";
import "./styles/Clients.css";
import ServicesRow from "../components/ServicesRow";
import { addService, updateService } from "../http/serviceController";
import toast from "react-hot-toast";
import usePagination from "../components/hooks/usePagination";
import { ITEMS_PER_PAGE_COUNT } from "../utils/consts";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import useServices from "../components/hooks/useServices";
import { isNumeric } from "validator";
import { isValidImageUrl, uploadImageToImbb } from "../utils/imbbUploader";

const Services = () => {
  const { page, handlePageChange } = usePagination(1);
  const { data, isLoading, refetch } = useServices(page);
  const fileInputRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    price: 0,
    url: "",
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [serviceToEditId, setServiceToEditId] = useState(null);

  const resetState = () => {
    setFormValues({
      name: "",
      description: "",
      price: 0,
      url: "",
    });
    setIsEditing(false);
    setServiceToEditId(null);
  };

  const handleFormChange = (e, name) => {
    const value = e.target.value;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const imageUrl = await uploadImageToImbb(file);
      if (imageUrl) {
        setFormValues((prev) => ({
          ...prev,
          url: imageUrl,
        }));
        toast.success("Зображення успішно завантажено");
      }
    } catch (error) {
      console.error("Помилка завантаження файлу:", error);
      toast.error("Не вдалося завантажити зображення");
    } finally {
      setIsUploading(false);
    }
  };

  const handleAddService = async () => {
    if (!formValues.name.trim()) {
      toast.error("Введіть назву послуги!");
      return;
    }

    if (!formValues.price || parseFloat(formValues.price) <= 0) {
      toast.error("Некоректне значення ціни!");
      return;
    }

    const newService = {
      name: formValues.name,
      description: formValues.description,
      price: Number(formValues.price),
      url: formValues.url,
    };

    try {
      let result;
      
      if (isEditing && serviceToEditId) {
        result = await updateService(serviceToEditId, newService);
        if (result) {
          toast.success("Послугу успішно оновлено!");
        }
      } else {
        result = await addService(newService);
        if (result) {
          toast.success("Послугу успішно додано!");
        }
      }
      
      if (result) {
        refetch();
        resetState();
        setShowModal(false);
      } else {
        toast.error("Щось пішло не так. Спробуйте пізніше!");
      }
    } catch (error) {
      console.error(`Помилка ${isEditing ? "оновлення" : "додавання"} послуги:`, error);
      toast.error(`Помилка ${isEditing ? "оновлення" : "додавання"} послуги. Спробуйте пізніше!`);
    }
  };
  
  const handleOpenEditModal = (service) => {
    setFormValues({
      name: service.name,
      description: service.description,
      price: service.price,
      url: service.url || "",
    });
    setIsEditing(true);
    setServiceToEditId(service.id);
    setShowModal(true);
  };

  return (
    <div className="root">
      <div className="header">
        <h2>Управління послугами</h2>
        <button
          className="action-btn primary"
          onClick={() => {
            resetState();
            setShowModal(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          Додати послугу
        </button>
      </div>

      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Завантаження послуг...</p>
        </div>
      ) : (
        <>
          <div className="user-table">
            <Table hover page-size="4">
              <thead>
                <tr>
                  <th style={{ width: "20vw" }}>Назва послуги</th>
                  <th style={{ width: "25vw" }}>Опис послуги</th>
                  <th style={{ width: "15vw" }}>Фото</th>
                  <th style={{ width: "10vw" }}>Вартість</th>
                  <th style={{ width: "7vw" }}>Дії</th>
                </tr>
              </thead>
              <tbody>
                {!data?.services?.length ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Послуг немає
                    </td>
                  </tr>
                ) : (
                  data.services.map((servicesItem) => {
                    return (
                      <ServicesRow 
                        key={servicesItem.id} 
                        service={servicesItem} 
                        onEdit={handleOpenEditModal}
                      />
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
          {data?.totalPages > 1 && (
            <Pagination
              current={page}
              total={data.totalPages * ITEMS_PER_PAGE_COUNT}
              pageSize={ITEMS_PER_PAGE_COUNT}
              onChange={handlePageChange}
              className="pagination"
            />
          )}
        </>
      )}

      <Modal
        className="service-modal"
        show={showModal}
        onHide={() => {
          setShowModal(false);
          resetState();
        }}
        centered
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Редагувати послугу" : "Додати нову послугу"}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="service-modal-body">
          <div className="service-form-container">
            <div className="service-form-fields">
              <div className="input_block">
                <p>Назва послуги</p>
                <input
                  type="text"
                  placeholder="Введіть назву послуги"
                  value={formValues.name}
                  onChange={(e) => handleFormChange(e, "name")}
                />
              </div>

              <div className="input_block">
                <p>Опис послуги</p>
                <textarea
                  placeholder="Детальний опис послуги"
                  onChange={(e) => handleFormChange(e, "description")}
                  value={formValues.description}
                />
              </div>

              <div className="input_block">
                <p>Фото послуги</p>
                <div className="file-upload-container">
                  <input
                    type="text"
                    placeholder="Посилання на фото"
                    value={formValues.url}
                    onChange={(e) => handleFormChange(e, "url")}
                    className="photo-url-input"
                  />
                  <div className="file-upload-actions">
                    <button
                      type="button"
                      className="action-btn upload-btn"
                      onClick={() => fileInputRef.current.click()}
                      disabled={isUploading}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="17 8 12 3 7 8"></polyline>
                        <line x1="12" y1="3" x2="12" y2="15"></line>
                      </svg>
                      {isUploading ? "Завантаження..." : "Завантажити фото"}
                    </button>
                    {formValues.url && (
                      <button
                        type="button"
                        className="action-btn clear-btn"
                        onClick={() => setFormValues(prev => ({ ...prev, url: "" }))}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                        Очистити
                      </button>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                    accept="image/*"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>

              <div className="input_block">
                <p>Ціна послуги (грн)</p>
                <input
                  type="text"
                  placeholder="Наприклад: 1200"
                  value={formValues.price}
                  onChange={(e) => handleFormChange(e, "price")}
                />
              </div>
            </div>

            <div className="service-preview">
              <div className="service-preview-header">
                <h3>Перегляд послуги</h3>
              </div>

              <div className="service-preview-content">
                <div className="service-preview-card">
                  {formValues.name && (
                    <div className="service-preview-name">{formValues.name}</div>
                  )}

                  {formValues.description && (
                    <div className="service-preview-description">{formValues.description}</div>
                  )}

                  {formValues.url && isValidImageUrl(formValues.url) && (
                    <div className="service-image-preview">
                      <img src={formValues.url} alt="Перегляд фото послуги" />
                    </div>
                  )}

                  {formValues.price > 0 && (
                    <div className="service-preview-price">
                      <span className="price-badge">{formValues.price} грн</span>
                    </div>
                  )}
                </div>

                <div className="service-preview-info">
                  <div className="preview-info-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>
                    <span>Так бачитимуть послугу користувачі</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="modal-actions">
            <button
              className="action-btn primary"
              onClick={handleAddService}
              disabled={isUploading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {isEditing ? (
                  <path d="M20 6L9 17l-5-5"></path>
                ) : (
                  <>
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </>
                )}
              </svg>
              {isEditing ? "Зберегти зміни" : "Додати послугу"}
            </button>

            <button
              onClick={() => {
                setShowModal(false);
                resetState();
              }}
              className="action-btn cancel-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
              Скасувати
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Services;
