import { useState } from "react";
import {Modal } from "react-bootstrap";
import "./styles/UserRow.css";
import { format } from "date-fns";

const UserRow = ({
  user,
  onDeleteUser,
  onAddSubscription,
  onBlockUser,
  OnAddUser,
  OnRemoveUser,
  OnChangePlan,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [subscriptionDays, setSubscriptionDays] = useState();
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleAddUser = () => {
    OnAddUser(user.id);
  };

  const handleDeleteUser = () => {
    onDeleteUser(user.id);
  };

  const handleAddSubscription = () => {
    onAddSubscription(user.id, subscriptionDays);
  };

  const handleRemovedUser = () => {
    OnRemoveUser(user.id);
  };

  const handleBlockUser = () => {
    onBlockUser(user.id);
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan); // Store the selected plan
    setShowModal(true); // Keep the modal open for confirmation
    setSelectedAction("confirmChangeSubscription"); // Set action to confirmation
  };

  const confirmSubscriptionChange = async () => {
    if (selectedPlan) {
      try {
        await OnChangePlan(user.id, selectedPlan);
        setShowModal(false); // Close the modal after successful change
        await new Promise((r) => setTimeout(r, 1000));
        window.location.reload(true);
      } catch (error) {
        console.error("Failed to change subscription:", error);
      }
    }
  };

  const formatDate = (dateString) => {
    try {
      if (!dateString) return "";
      const date = new Date(dateString);
      // Перевірка чи дата валідна (не NaN)
      if (isNaN(date.getTime())) return "Невалідна дата";
      return format(date, "dd.MM.yyyy");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Невалідна дата";
    }
  };

  const handleShowPreviousSubs = () => {
    setSelectedAction("showPreviousSubs");
    setShowModal(true);
  };

  const handleShowLatestActivity = () => {
    setSelectedAction("showLatestActivity");
    setShowModal(true);
  };

  // Функция для извлечения срока подписки из описания
  const extractSubscriptionDuration = (description) => {
    if (!description) return "Не визначено";
    const regex = /(\d+)\s+місяць|місяці|місяців/; // Группируем варианты слов "місяць", "місяці", "місяців"
    const match = description.match(regex); // Ищем число и слово "місяць", "місяці" или "місяців"
    return match ? match[0] : "Не визначено"; // Возвращаем найденную строку или "Не визначено"
  };


  // Функція для відображення бейджа підписки
  const renderSubscriptionBadge = (plan) => {
    // Якщо передано параметр, використовуємо його, інакше використовуємо план користувача
    const planToRender = plan || user?.activePlan || "";
    
    if (!planToRender || planToRender === "--" || planToRender === "none") {
      return <span className="plan-badge none">Немає</span>;
    } else if (planToRender === "Bronze") {
      return <span className="plan-badge bronze">Bronze</span>;
    } else if (planToRender === "Silver") {
      return <span className="plan-badge silver">Silver</span>;
    } else if (planToRender === "Gold") {
      return <span className="plan-badge gold">Gold</span>;
    } else if (planToRender === "Platinum") {
      return <span className="plan-badge platinum">Platinum</span>;
    }
    
    return <span>{planToRender}</span>;
  };

  return (
    <tr key={user.id}>
      <td>{user.name}</td>
      <td>{user.phoneNumber}</td>
      <td>{user.birthDate || ""}</td>
      <td>{renderSubscriptionBadge()}</td>
      <td>{user.leftDays}</td>
      <td>
        {user.subscribeEndDate ? formatDate(user.subscribeEndDate) : ""}
      </td>
      <td>
        <button
          onClick={handleShowPreviousSubs}
          className="viewPreviousSubsBtn"
        >
          <span>Показати підписки</span>
        </button>
      </td>
      <td>
        <div className="actionBlock">
          {/* Перший ряд - тільки кнопка "Нагородити його днями" */}
          <div className="addDaysWrapper">
            <span className="days-label">Нагородити днями:</span>
            <div className="addDaysForm">
              <input
                className="addDaysInput"
                type="text"
                placeholder="Дні"
                value={subscriptionDays}
                onChange={(e) => setSubscriptionDays(e.target.value)}
              />
              <button
                onClick={() => {
                  setShowModal(true);
                  setSelectedAction("addSubscription");
                }}
                className="addDaysBtn action-btn"
              >
                <img
                  src="/clientsImg/plus.svg"
                  alt="Add subscribe days"
                  title="Add subscribe days"
                />
              </button>
            </div>
          </div>

          {/* Другий ряд - три кнопки */}
          <div className="action-row">
            <button
              onClick={() => {
                setShowModal(true);
                setSelectedAction("block");
              }}
              className="blockUserBtn action-btn"
            >
              <img
                src="/clientsImg/block.svg"
                alt="Block user"
                title="Block user"
              />
            </button>

            <button
              onClick={() => {
                setShowModal(true);
                setSelectedAction("delete");
              }}
              className="deleteUserBtn action-btn"
            >
              <img
                src="/clientsImg/trash.svg"
                alt="Delete user"
                title="Delete user"
              />
            </button>

            <button
              onClick={() => {
                setShowModal(true);
                setSelectedAction("add");
              }}
              className="addUserBtn action-btn"
            >
              <img
                src="/clientsImg/join.png"
                alt="Додати користувача до чату"
                title="Додати користувача до чату"
              />
            </button>
          </div>
          {/* Третій ряд - три кнопки */}
          <div className="action-row">
            <button
              onClick={() => {
                setShowModal(true);
                setSelectedAction("remove");
              }}
              className="RemoveUserBtn action-btn"
            >
              <img
                src="/clientsImg/remove.png"
                alt="Видалити користувача з чату"
                title="Видалити користувача з чату"
              />
            </button>
            
            <button
              onClick={() => {
                setShowModal(true);
                setSelectedAction("changeSubscription");
              }}
              className="changeSubscriptionBtn action-btn"
            >
              <img
                src="/clientsImg/sync.png"
                alt="Змінити підписку"
                title="Змінити підписку"
              />
            </button>
            
            <button
              onClick={handleShowLatestActivity}
              className="latestActivityBtn action-btn"
            >
              <img
                src="/clientsImg/shopping-cart.png"
                alt="Показати останню активність"
                title="Показати останню активність"
              />
            </button>
          </div>
        </div>
      </td>

      <Modal
        className="modalWindow"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size={
          selectedAction === "showPreviousSubs" ||
          selectedAction === "showLatestActivity"
            ? "md"
            : undefined
        }
      >
        <Modal.Header>
          {selectedAction === "delete"
            ? "Вигнати його з нашого району?"
            : selectedAction === "addSubscription"
            ? "Нагородити його днями?"
            : selectedAction === "add"
            ? "Додати користувача до чату?"
            : selectedAction === "remove"
            ? "Видалити користувача з чату?"
            : selectedAction === "block"
            ? "Заблокувати цього порушника?"
            : selectedAction === "showPreviousSubs"
            ? "Попередні підписки"
            : selectedAction === "showLatestActivity"
            ? "Остання активність"
            : selectedAction === "changeSubscription"
            ? "Зміна типу підписки"
            : selectedAction === "confirmChangeSubscription"
            ? "Підтвердження зміни підписки"
            : ""}
        </Modal.Header>

        <Modal.Body>
          {selectedAction === "changeSubscription" && (
            <div className="subscription-options-container">
              <h4>Виберіть новий тип підписки</h4>
              <div className="subscription-options">
                <button 
                  className="subscription-option-btn bronze"
                  onClick={() => handleSelectPlan("Bronze")}
                >
                  Bronze
                </button>
                <button 
                  className="subscription-option-btn silver"
                  onClick={() => handleSelectPlan("Silver")}
                >
                  Silver
                </button>
                <button 
                  className="subscription-option-btn gold"
                  onClick={() => handleSelectPlan("Gold")}
                >
                  Gold
                </button>
                <button 
                  className="subscription-option-btn platinum"
                  onClick={() => handleSelectPlan("Platinum")}
                >
                  Platinum
                </button>
                <button 
                  className="subscription-option-btn none"
                  onClick={() => handleSelectPlan("none")}
                >
                  Немає
                </button>
              </div>
            </div>
          )}

          {selectedAction === "confirmChangeSubscription" && (
            <div>
              <p>Ви впевнені, що хочете змінити тип підписки на <strong>{selectedPlan}</strong>?</p>
              <div className="modal-actions">
                <button onClick={confirmSubscriptionChange} className="confirmChangeBtn action-btn">
                  Підтвердити
                </button>
                <button onClick={() => setShowModal(false)} className="cancelChangeBtn action-btn">
                  Скасувати
                </button>
              </div>
            </div>
          )}

          {selectedAction === "showPreviousSubs" && (
            <div className="previous-subscriptions">
              {user.approvedInvoices && user.approvedInvoices.length > 0 ? (
                <div>
                  <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                    {user.approvedInvoices.map((invoice, index) => (
                      <li
                        key={index}
                        style={{ marginBottom: "10px", lineHeight: "1.6" }}
                      >
                        <strong>Сума рахунку:</strong> {invoice.amount || "0"} UAH,
                        <strong> Дата:</strong> {formatDate(invoice.paidDate)},
                        <strong> Тип підписки:</strong> {invoice.plan || "Невідомо"} (
                        {extractSubscriptionDuration(invoice.description)})
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="no-data">Немає одобрених рахунків</p>
              )}
            </div>
          )}

          {selectedAction === "showLatestActivity" && (
            <div className="latest-activity">
              {user.userActivities && user.userActivities.length > 0 ? (
                <div>
                  <table className="activity-table">
                    <thead>
                      <tr>
                        <th>Дата</th>
                        <th>Опис</th>
                        <th>Статус</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.userActivities.map((activity, index) => (
                        <tr key={index}>
                          <td>{activity.date ? formatDate(activity.date) : "Невідомо"}</td>
                          <td>{activity.description || "Немає опису"}</td>
                          <td>
                            <span className={`status-badge ${activity.status || ""}`}>
                              {activity.status === "approved"
                                ? "Схвалено"
                                : activity.status === "waiting_for_payment"
                                ? "Очікує оплати"
                                : activity.status || "Невідомо"}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-data">Немає активності</p>
              )}
            </div>
          )}

          {(selectedAction === "delete" ||
            selectedAction === "addSubscription" ||
            selectedAction === "block" ||
            selectedAction === "add" ||
            selectedAction === "remove") && (
            <div className="modal-actions">
              <button
                onClick={() => {
                  if (selectedAction === "delete") {
                    handleDeleteUser();
                  } else if (selectedAction === "addSubscription") {
                    handleAddSubscription();
                  } else if (selectedAction === "block") {
                    handleBlockUser();
                  } else if (selectedAction === "add") {
                    handleAddUser();
                  } else if (selectedAction === "remove") {
                    handleRemovedUser();
                  }
                  setShowModal(false);
                }}
                className="action-btn"
              >
                Підтвердити
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                className="action-btn"
              >
                Скасувати
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </tr>
  );
};

export default UserRow;
