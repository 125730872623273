import Main from "./pages/Main";
import {
  CLIENTS_ROUTE,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  SERVICES_ROUTE,
  NOTIFICATION_ROUTE,
  BUTTONS_ROUTE,
  PACKAGES_ROUTE,
} from "./utils/consts";
import Clients from "./pages/Clients";
import Login from "./pages/Login";
import Services from "./pages/Services";
import Notifications from "./pages/Notifications";

import Buttons from "./pages/Buttons";
import Packages from "./pages/Packages";

export const authRoutes = [
  {
    path: MAIN_ROUTE,
    Component: Main,
  },
  {
    path: CLIENTS_ROUTE,
    Component: Clients,
  },
  {
    path: SERVICES_ROUTE,
    Component: Services,
  },
  {
    path: PACKAGES_ROUTE,
    Component: Packages,
  },
  {
    path: BUTTONS_ROUTE,
    Component: Buttons,
  },
  {
    path: NOTIFICATION_ROUTE,
    Component: Notifications,
  },
];

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: Login,
  },
];
