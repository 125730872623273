export function shortenImageUrl(url) {
  if (url) {
    const urlObj = new URL(url);
    const pathSegments = urlObj.pathname.split("/");

    const shortenedPath = `/.../${pathSegments[pathSegments.length - 1]}`;

    return `${urlObj.protocol}//${urlObj.host}${shortenedPath}`;
  }
}
