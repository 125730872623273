import "./styles/UserRow.css";

const packageTypes = {
  bronze: "Bronze 🥉",
  silver: "Silver 🥈",
  gold: "Gold 🥇",
  platinum: "Platinum 💎",
  diamond: "Diamond 💠",
};

const PackagesRow = ({
  item,
  setPackage,
  setShowEditModal,
}) => {
  const displayType = packageTypes[item.type] || item.type || "--";
  
  // Визначаємо клас для бейджа
  const getBadgeClass = () => {
    switch(item.type) {
      case 'bronze': return 'plan-badge bronze';
      case 'silver': return 'plan-badge silver';
      case 'gold': return 'plan-badge gold';
      case 'platinum': return 'plan-badge platinum';
      case 'diamond': return 'plan-badge diamond';
      default: return 'plan-badge none';
    }
  };

  return (
    <tr key={item.id}>
      <td>
        {/* Показуємо тільки першу частину тексту в таблиці */}
        {item.text ? (
          item.text.length > 60 
            ? item.text.slice(0, 60) + '...' 
            : item.text
        ) : "--"}
      </td>
      <td>{item.url || "--"}</td>
      <td>
        <span className={getBadgeClass()}>
          {displayType}
        </span>
      </td>
      <td>
        <div className="actionBlock">
          <button
            onClick={() => {
              setShowEditModal(true);
              setPackage({
                id: item.id,
                text: item.text,
                type: item.type,
                url: item.url,
              });
            }}
            className="action-btn"
            title="Редагувати пакет"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              className="edit-icon"
            >
              <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
              <path d="m15 5 4 4"></path>
            </svg>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default PackagesRow;
