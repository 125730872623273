import React, { useEffect, useState, useRef, useCallback } from "react";
import { Table } from "react-bootstrap";
import "./styles/Clients.css";
import remarkBreaks from "remark-breaks";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import {
  getAboutButton,
  getFaqButton,
  getMissionButton,
  getSponsorsButton,
  getTeamButton,
  getSocialButton,
  putAboutButton,
  putFaqButton,
  putMissionButton,
  putSponsorsButton,
  putTeamButton,
  putSocialButton,
} from "../http/buttonsController";
import useCalendar from "../components/hooks/useCalendar";
import useYoutube from "../components/hooks/useYoutube";
import { updateYoutube } from "../http/youtubeController";
import { updateCalendar } from "../http/calendarController";
import { shortenImageUrl } from "../utils/imageShortener";
import { isValidImageUrl, uploadImageToImbb } from "../utils/imbbUploader";
import { useQueryClient } from "@tanstack/react-query";

const buttonTypes = {
  aboutClubMessage: "Про клуб ⚽",
  sponsorsMessage: "Спонсорам 💰",
  teamMessage: "Наша команда 👥",
  faqMessage: "FAQ ❓",
  missionMessage: "Наша місія 🎯",
  youtube: "Youtube підбірка 🎥",
  calendar: "Календар подій 📅",
  socialMessage: "Ми в соцмережах 🌐",
};

const Buttons = () => {
  const calendarQuery = useCalendar();
  const youtubeQuery = useYoutube();
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();

  const [youtubeButton, setYoutubeButton] = useState(null);
  const [calendarButton, setCalendarButton] = useState(null);
  const [socialButton, setSocialButton] = useState(null);
  const [aboutButton, setAboutButton] = useState(null);
  const [faqButton, setFaqButton] = useState(null);
  const [missionButton, setMimissionButton] = useState(null);
  const [teamButton, setTeamButton] = useState(null);
  const [sponsorsButton, setSponsorsButton] = useState(null);
  const [markDown, setMarkDown] = useState("");
  const [previewMarkDown, setPreviewMarkDown] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buttonToEdit, setButtonToEdit] = useState({
    key: "",
    text: "",
    url: "",
  });
  const [url, setUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleMarkdown = (e) => {
    const value = e.target.value;
    setMarkDown(value);
    // Обробляємо markdown для відображення без зайвих переносів рядків
    // Замінюємо подвійні переноси рядків на спеціальний маркер, щоб зберегти їх
    const processedValue = value
      .replace(/\n\n/g, '\n&nbsp;\n')  // Зберігаємо подвійні переноси
      .replace(/__(.*?)__/g, "*$1*")
      .replace(/\*(.*?)\*/g, "**$1**");
    
    setPreviewMarkDown(processedValue);
  };

  const resetState = () => {
    setMarkDown("");
    setPreviewMarkDown("");
    setUrl("");
  };

  const currentDate = new Date().toLocaleString('uk-UA', {
    hour: '2-digit',
    minute: '2-digit'
  });

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const imageUrl = await uploadImageToImbb(file);
      if (imageUrl) {
        setUrl(imageUrl);
      }
    } catch (error) {
      console.error("Помилка завантаження файлу:", error);
      toast.error("Не вдалося завантажити зображення");
    } finally {
      setIsUploading(false);
    }
  };

  const handleEditButton = async (e) => {
    e.preventDefault();

    let buttonController;

    switch (buttonToEdit.key) {
      case "aboutClubMessage":
        buttonController = putAboutButton;
        break;
      case "sponsorsMessage":
        buttonController = putSponsorsButton;
        break;
      case "teamMessage":
        buttonController = putTeamButton;
        break;
      case "faqMessage":
        buttonController = putFaqButton;
        break;
      case "youtube":
        buttonController = updateYoutube;
        break;
      case "calendar":
        buttonController = updateCalendar;
        break;
      case "socialMessage":
        buttonController = putSocialButton;
        break;
      default:
        buttonController = putMissionButton;
    }

    const updatedButton = {
      text: markDown,
      url,
    };

    try {
      const result = await buttonController(updatedButton);

      if (result) {
        toast.success("Кнопку успішно змінено!");
        setShowModal(false);
        resetState();
        
        // Оновлюємо дані через React Query замість перезавантаження сторінки
        queryClient.invalidateQueries(["buttons"]);
        queryClient.invalidateQueries(["calendar"]);
        queryClient.invalidateQueries(["youtube"]);
        await fetchButtons(); // Отримуємо актуальні дані кнопок
      } else {
        toast.error("Щось пішло не так. Спробуйте пізніше!");
      }
    } catch (error) {
      console.error("Помилка при оновленні кнопки:", error);
      toast.error("Помилка оновлення. Спробуйте пізніше!");
    }
  };

  const fetchButtons = useCallback(async () => {
    setIsLoading(true);
    try {
      const [
        aboutButtonData,
        faqButtonData,
        missionButtonData,
        teamButtonData,
        sponsorsButtonData,
        socialButtonData
      ] = await Promise.all([
        getAboutButton(),
        getFaqButton(),
        getMissionButton(),
        getTeamButton(),
        getSponsorsButton(),
        getSocialButton()
      ]);

      setAboutButton(aboutButtonData);
      setFaqButton(faqButtonData);
      setMimissionButton(missionButtonData);
      setTeamButton(teamButtonData);
      setSponsorsButton(sponsorsButtonData);
      setSocialButton(socialButtonData);
    } catch (error) {
      console.error("Помилка завантаження кнопок:", error);
      toast.error("Помилка завантаження даних");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchButtons();
  }, [fetchButtons]);

  useEffect(() => {
    if (!calendarQuery.isLoading && !youtubeQuery.isLoading) {
      if (calendarQuery.data?.message) {
        setCalendarButton({
          key: "calendar",
          text: calendarQuery.data.message.text,
          url: calendarQuery.data.message.url,
        });
      }
      
      if (youtubeQuery.data?.message) {
        setYoutubeButton({
          key: "youtube",
          text: youtubeQuery.data.message.text,
          url: youtubeQuery.data.message.url,
        });
      }
    }
  }, [calendarQuery.data, youtubeQuery.data, calendarQuery.isLoading, youtubeQuery.isLoading]);

  useEffect(() => {
    if (buttonToEdit && buttonToEdit.text) {
      setPreviewMarkDown(
        buttonToEdit.text
          .replace(/__(.*?)__/g, "*$1*")
          .replace(/\*(.*?)\*/g, "**$1**")
      );
      setMarkDown(buttonToEdit.text);
    }

    setUrl(buttonToEdit.url || "");
  }, [buttonToEdit]);

  if (isLoading || !aboutButton) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Завантаження даних...</p>
      </div>
    );
  }

  const formatButtonType = (key) => {
    return buttonTypes[key] || key;
  };

  // Визначаємо клас для бейджа
  const getButtonBadgeClass = (key) => {
    switch(key) {
      case 'aboutClubMessage': return 'plan-badge gold';
      case 'sponsorsMessage': return 'plan-badge platinum';
      case 'teamMessage': return 'plan-badge silver';
      case 'faqMessage': return 'plan-badge bronze';
      case 'missionMessage': return 'plan-badge diamond';
      case 'youtube': return 'plan-badge gold';
      case 'calendar': return 'plan-badge silver';
      case 'socialMessage': return 'plan-badge platinum';
      default: return 'plan-badge none';
    }
  };

  const buttons = [
    aboutButton,
    sponsorsButton,
    teamButton,
    faqButton,
    missionButton,
    calendarButton,
    youtubeButton,
    socialButton,
  ].filter(button => button !== null);

  return (
    <div className="root">
      {!showModal ? (
        <>
          <div className="header">
            <h2>Управління кнопками меню</h2>
          </div>
          <div className="user-table">
            <Table hover page-size="4">
              <thead>
                <tr>
                  <th style={{ width: "35vw" }}>Текст кнопки</th>
                  <th style={{ width: "15vw" }}>Фото (посилання)</th>
                  <th style={{ width: "15vw" }}>Тип кнопки</th>
                  <th style={{ width: "10vw" }}>Дії</th>
                </tr>
              </thead>

              <tbody>
                {buttons.map((button) => (
                  <tr
                    key={button?.message?.key || button?.key || Math.random()}
                  >
                    <td>
                      {/* Показуємо тільки першу частину тексту в таблиці */}
                      {(button?.message?.text || button?.text) ? (
                        (button?.message?.text || button?.text).length > 60 
                          ? (button?.message?.text || button?.text).slice(0, 60) + '...' 
                          : (button?.message?.text || button?.text)
                      ) : "--"}
                    </td>
                    <td>
                      {shortenImageUrl(button?.message?.url) ||
                        shortenImageUrl(button?.url) ||
                        "--"}
                    </td>
                    <td>
                      <span className={getButtonBadgeClass(button?.message?.key || button?.key)}>
                        {formatButtonType(button?.message?.key || button?.key)}
                      </span>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setShowModal(true);
                          setButtonToEdit({
                            key: button?.message?.key || button?.key,
                            text: button?.message?.text || button?.text,
                            url: button?.message?.url || button?.url,
                          });
                        }}
                        className="action-btn"
                        title="Редагувати кнопку"
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="20" 
                          height="20" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2" 
                          strokeLinecap="round" 
                          strokeLinejoin="round"
                          className="edit-icon"
                        >
                          <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
                          <path d="m15 5 4 4"></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <div className="notify-form">
          <form onSubmit={handleEditButton}>
            <div className="input_block">
              <p>Текст</p>
              <textarea
                value={markDown}
                onChange={handleMarkdown}
                placeholder="Введіть текст: *жирний*, _курсив_, натиснути Enter - перенос рядка, [Перейти на Google](https://www.google.com)"
                rows={20}
                cols={50}
              />
            </div>
            <div className="input_block">
              <p>Фото</p>
              <div className="file-upload-container">
                <input
                  type="text"
                  placeholder="Посилання на фото"
                  onChange={(e) => setUrl(e.target.value)}
                  value={url}
                  className="photo-url-input"
                />
                <div className="file-upload-actions">
                  <button 
                    type="button" 
                    className="action-btn"
                    onClick={() => fileInputRef.current.click()}
                    disabled={isUploading}
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                      <polyline points="17 8 12 3 7 8"></polyline>
                      <line x1="12" y1="3" x2="12" y2="15"></line>
                    </svg>
                    {isUploading ? "Завантаження..." : "Завантажити фото"}
                  </button>
                  {url && (
                    <button 
                      type="button" 
                      className="action-btn"
                      onClick={() => setUrl("")}
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="18" 
                        height="18" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                      Очистити
                    </button>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            {url && isValidImageUrl(url) && (
              <div className="service-image-preview">
                <img src={url} alt="Перегляд фото" />
              </div>
            )}
            <div className="form-actions">
              <button type="submit" className="action-btn primary">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="18" 
                  height="18" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
                Зберегти зміни
              </button>
              <button
                type="button"
                className="action-btn"
                onClick={() => {
                  setShowModal(false);
                  resetState();
                }}
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="18" 
                  height="18" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
                Скасувати
              </button>
            </div>
          </form>
          <div className="preview">
            <div className="telegram-preview">
              <div className="telegram-preview-header">
                Перегляд повідомлення
              </div>
              <div className="telegram-message">
                <div className="telegram-avatar">
                  <div className="bot-avatar">FC</div>
                </div>
                <div className="telegram-content">
                  <div className="telegram-username">FC Мрія</div>
                  <div className="telegram-text">
                    <ReactMarkdown 
                      remarkPlugins={[remarkBreaks]} 
                      components={{
                        p: ({node, ...props}) => <div {...props} style={{marginBottom: '0.5em'}} />,
                        br: () => <br style={{display: 'block', margin: '0.3em 0'}} />
                      }}
                    >
                      {previewMarkDown}
                    </ReactMarkdown>
                  </div>
                  {url && isValidImageUrl(url) && (
                    <div className="telegram-image">
                      <img src={url} alt="Зображення до повідомлення" />
                    </div>
                  )}
                  {url && !isValidImageUrl(url) && (
                    <div className="telegram-image-placeholder">
                      <em>[Фото буде відображатись тут]</em>
                    </div>
                  )}
                  <div className="telegram-date">{currentDate}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Buttons;
