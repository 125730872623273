import { useQuery } from "@tanstack/react-query";
import { getNotifications } from "../../http/notificationController";

const useNotifications = (page) => {
  return useQuery({
    queryKey: ["notifications", page],
    queryFn: () => getNotifications(page),
  });
};

export default useNotifications;
