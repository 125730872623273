import { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import "./styles/UserRow.css";
import { deleteServiceById, updateService } from "../http/serviceController";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { isNumeric } from "validator";
import { isValidImageUrl, uploadImageToImbb } from "../utils/imbbUploader";

const ServicesRow = ({ service, onEdit }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    price: 0,
    url: "",
  });

  const resetState = () => {
    setFormValues({
      name: "",
      description: "",
      price: "",
      url: "",
    });
  };

  const handleFormChange = (e, name) => {
    const value = e.target.value;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const imageUrl = await uploadImageToImbb(file);
      if (imageUrl) {
        setFormValues((prev) => ({
          ...prev,
          url: imageUrl,
        }));
      }
    } catch (error) {
      console.error("Помилка завантаження файлу:", error);
      toast.error("Не вдалося завантажити зображення");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      const result = await deleteServiceById(serviceId);

      if (result) {
        toast.success("Послуга успішно видалена!");
      } else {
        toast.error("Щось пішло не так. Спробуйте пізніше!");
      }
      queryClient.invalidateQueries("services");
      setShowModal(false);
    } catch (error) {
      console.error("Помилка видалення послуги:", error);
      toast.error("Помилка видалення послуги. Спробуйте пізніше!");
    }
  };

  const handleEditService = async (serviceId) => {
    if (!isNumeric(formValues.price) || parseFloat(formValues.price) <= 0) {
      toast.error("Некоректне значення ціни!");
      return;
    }
    const updatedService = {
      name: formValues.name,
      description: formValues.description,
      price: Number(formValues.price),
      url: formValues.url,
    };
    const result = await updateService(serviceId, updatedService);

    if (result) {
      toast.success("Послуга успішно змінена!");
    } else {
      toast.error("Щось пішло не так. Спробуйте пізніше!");
    }

    queryClient.invalidateQueries("services");
    setShowModal(false);
    resetState();
  };

  useEffect(() => {
    setFormValues({
      name: service.name,
      description: service.description,
      price: service.price,
      url: service.url,
    });
  }, [service]);

  return (
    <tr key={service.id}>
      <td>{service.name || "--"}</td>
      <td>
        {service.description 
          ? (service.description.length > 60 
              ? service.description.slice(0, 60) + '...' 
              : service.description) 
          : "--"}
      </td>
      <td>{service.url ? 
            <a href={service.url} target="_blank" rel="noopener noreferrer" className="service-url">
              {service.url.length > 30 ? service.url.slice(0, 30) + '...' : service.url}
            </a> 
            : "--"}
      </td>
      <td>
        <span className="price-badge">
          {service.price || "--"} ₴
        </span>
      </td>
      <td>
        <div className="actionBlock">
          <button
            onClick={() => onEdit(service)}
            className="action-btn"
            title="Редагувати послугу"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              className="edit-icon"
            >
              <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
              <path d="m15 5 4 4"></path>
            </svg>
          </button>
          <button
            onClick={() => setShowModal(true)}
            className="action-btn danger"
            title="Видалити послугу"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              className="delete-icon"
            >
              <path d="M3 6h18"></path>
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </button>
        </div>
      </td>
      <Modal
        className="modalWindow"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Видалення послуги</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="confirm-delete-text">Ви впевнені, що хочете видалити цю послугу?</p>
          <p className="service-to-delete-name">{service.name}</p>
        </Modal.Body>

        <Modal.Footer>
          <div className="modal-actions">
            <button
              className="action-btn danger"
              onClick={() => handleDeleteService(service.id)}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="18" 
                height="18" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <path d="M3 6h18"></path>
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
              </svg>
              Видалити
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="action-btn cancel-btn"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="18" 
                height="18" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
              Скасувати
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

export default ServicesRow;
