import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import "./styles/Clients.css";
import toast from "react-hot-toast";

import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

import { useQueryClient } from "@tanstack/react-query";

import "rc-pagination/assets/index.css";
import usePackages from "../components/hooks/usePackages";
import { putPackageByType } from "../http/packageController";
import PackagesRow from "../components/PackagesRow";

const Packages = observer(() => {
  const { data, isLoading } = usePackages();

  const [markDown, setMarkDown] = useState("");
  const [previewMarkDown, setPreviewMarkDown] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const [packageToEdit, setPackageToEdit] = useState({
    id: "",
    text: "",
    type: "",
    url: "",
  });

  const queryClient = useQueryClient();

  const resetState = () => {
    setMarkDown("");
    setPreviewMarkDown("");
    setUrl("");
  };

  const handleEditPackage = async (e) => {
    e.preventDefault();

    const newPackage = {
      text: markDown,
      type: packageToEdit.type,
      url,
    };
    const result = await putPackageByType(newPackage);

    if (result) {
      toast.success("Пакет успішно оновлено!");
    } else {
      toast.error("Щось пішло не так. Спробуйте пізніше!");
    }
    setShowModal(false);
    resetState();
    queryClient.invalidateQueries("packages");
  };

  const handleMarkdown = (e) => {
    const value = e.target.value;
    setMarkDown(value);
    setPreviewMarkDown(
      value.replace(/__(.*?)__/g, "*$1*").replace(/\*(.*?)\*/g, "**$1**")
    );
  };

  useEffect(() => {
    if (packageToEdit && packageToEdit.text) {
      setMarkDown(packageToEdit.text);
      setPreviewMarkDown(
        packageToEdit.text
          .replace(/__(.*?)__/g, "*$1*")
          .replace(/\*(.*?)\*/g, "**$1**")
      );
      setUrl(packageToEdit.url);
    }
  }, [packageToEdit]);

  if (isLoading) return null;

  return (
    <div className="root">
      {!showModal ? (
        <>
          <div className="user-table">
            <Table hover page-size="4">
              <thead style={{ width: "100%" }}>
                <tr>
                  <th style={{ width: "25vw" }}>Текст пакету</th>
                  <th style={{ width: "15vw" }}>Фото (посилання)</th>
                  <th style={{ width: "5vw" }}>Тип пакету</th>
                  <th style={{ width: "5vw" }}>Дії</th>
                </tr>
              </thead>
              <tbody>
                {data.messages && data.messages.length > 0
                  ? data.messages.map((msg) => {
                      return (
                        <PackagesRow
                          key={msg.id}
                          item={msg}
                          setPackage={setPackageToEdit}
                          setShowEditModal={setShowModal}
                        />
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <div className="notify-form">
          <form onSubmit={handleEditPackage}>
            <div className="input_block">
              <p>Текст</p>
              <textarea
                value={markDown}
                onChange={(e) => handleMarkdown(e)}
                placeholder="Введіть текст: *жирний*, _курсив_, натиснути Enter - перенос рядка, [Перейти на Google](https://www.google.com)"
                rows={20}
                cols={50}
              />
            </div>
            {url && (
              <div
                className="image-preview"
                style={{ backgroundImage: `url(${url})` }}
              />
            )}
            <div className="input_block">
              <p>Посилання на фото</p>
              <input
                type="text"
                placeholder="Посилання на фото"
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
            </div>
            <button type="submit">Зберегти</button>
            <button
              type="button"
              onClick={() => {
                setShowModal(false);
                resetState();
              }}
            >
              Скасувати
            </button>
          </form>
          <div className="preview">
            <p>Текст preview:</p>
            <ReactMarkdown remarkPlugins={[remarkBreaks]}>
              {previewMarkDown}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
});

export default Packages;
