import { $authHost } from ".";

export const getAboutButton = async () => {
  try {
    const { data } = await $authHost.get("api/about-club");
    return data;
  } catch (error) {
    console.log("Error geting about button: ", error);
  }
};
export const getFaqButton = async () => {
  try {
    const { data } = await $authHost.get("api/faq");
    return data;
  } catch (error) {
    console.log("Error geting faq button: ", error);
  }
};
export const getMissionButton = async () => {
  try {
    const { data } = await $authHost.get("api/mission");
    return data;
  } catch (error) {
    console.log("Error geting mission button: ", error);
  }
};
export const getSponsorsButton = async () => {
  try {
    const { data } = await $authHost.get("api/sponsors");
    return data;
  } catch (error) {
    console.log("Error geting sponsors button: ", error);
  }
};
export const getTeamButton = async () => {
  try {
    const { data } = await $authHost.get("api/team");
    return data;
  } catch (error) {
    console.log("Error geting team button: ", error);
  }
};
export const getSocialButton = async () => {
  try {
    const { data } = await $authHost.get("api/social");
    return data;
  } catch (error) {
    console.log("Error geting sponsors button: ", error);
  }
};

export const putAboutButton = async (updatedButton) => {
  try {
    const { data } = await $authHost.put("api/about-club", updatedButton);
    return data;
  } catch (error) {
    console.log("Error putting about button: ", error);
  }
};
export const putFaqButton = async (updatedButton) => {
  try {
    const { data } = await $authHost.put("api/faq", updatedButton);
    return data;
  } catch (error) {
    console.log("Error putting faq button: ", error);
  }
};
export const putMissionButton = async (updatedButton) => {
  try {
    const { data } = await $authHost.put("api/mission", updatedButton);
    return data;
  } catch (error) {
    console.log("Error putting mission button: ", error);
  }
};
export const putSponsorsButton = async (updatedButton) => {
  try {
    const { data } = await $authHost.put("api/sponsors", updatedButton);
    return data;
  } catch (error) {
    console.log("Error putting sponsors button: ", error);
  }
};
export const putTeamButton = async (updatedButton) => {
  try {
    const { data } = await $authHost.put("api/team", updatedButton);
    return data;
  } catch (error) {
    console.log("Error putting team button: ", error);
  }
};
export const putSocialButton = async (updatedButton) => {
  try {
    const { data } = await $authHost.put("api/social", updatedButton);
    return data;
  } catch (error) {
    console.log("Error putting team button: ", error);
  }
};