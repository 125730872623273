import toast from 'react-hot-toast';

// API ключ для ImBB - використовуємо змінну оточення з правильним префіксом
const IMGBB_API_KEY = process.env.REACT_APP_IMGBB_API_KEY || 'b13c4d34581a65943ebd1e4cc6087cc9'; // Резервний ключ, якщо змінна оточення не встановлена

/**
 * Завантажує зображення на ImBB
 * @param {File} file - Файл зображення для завантаження
 * @returns {Promise<string|null>} URL завантаженого зображення або null у випадку помилки
 */
export const uploadImageToImbb = async (file) => {
  if (!file) {
    toast.error('Файл не вибрано');
    return null;
  }

  // Перевірка типу файлу
  if (!file.type.match(/image\/(jpeg|jpg|png|gif|webp)/i)) {
    toast.error('Дозволені формати: JPEG, PNG, GIF, WebP');
    return null;
  }

  // Перевірка розміру файлу (не більше 5 МБ)
  if (file.size > 5 * 1024 * 1024) {
    toast.error('Розмір файлу не має перевищувати 5 МБ');
    return null;
  }

  try {
    const formData = new FormData();
    formData.append('image', file);

    const response = await fetch(`https://api.imgbb.com/1/upload?key=${IMGBB_API_KEY}`, {
      method: 'POST',
      body: formData
    });

    const data = await response.json();
    
    if (data.success) {
      toast.success('Зображення успішно завантажено');
      return data.data.url;
    } else {
      console.error('ImgBB відповідь з помилкою:', data);
      toast.error(data.error?.message || 'Помилка завантаження зображення');
      return null;
    }
  } catch (error) {
    console.error('Помилка завантаження зображення на ImBB:', error);
    toast.error('Помилка завантаження зображення. Спробуйте пізніше.');
    return null;
  }
};

/**
 * Перевіряє, чи є рядок валідною URL-адресою зображення
 * @param {string} url - URL-адреса для перевірки
 * @returns {boolean} true, якщо це валідна URL-адреса зображення
 */
export const isValidImageUrl = (url) => {
  if (!url) return false;
  
  try {
    const urlObj = new URL(url);
    return urlObj.protocol === 'http:' || urlObj.protocol === 'https:';
  } catch (e) {
    return false;
  }
};

/**
 * Перетворює URL-адресу зображення на більш читабельний формат для відображення
 * @param {string} url - URL-адреса зображення
 * @returns {string} Скорочена або форматована URL-адреса
 */
export const formatImageUrl = (url) => {
  if (!url) return '';
  
  // Якщо це ImBB URL, показуємо тільки останню частину
  if (url.includes('ibb.co')) {
    try {
      const urlObj = new URL(url);
      return `${urlObj.hostname}/${urlObj.pathname.split('/').pop()}`;
    } catch (e) {
      return url;
    }
  }
  
  return url;
};

export default {
  uploadImageToImbb,
  isValidImageUrl,
  formatImageUrl
};