import { $authHost } from ".";

export const getCalendar = async () => {
  try {
    const { data } = await $authHost.get("api/calendar");
    return data;
  } catch (error) {
    console.log("Error geting calendar: ", error);
  }
};
export const updateCalendar = async (updatedCalendar) => {
  try {
    const { data } = await $authHost.put("api/calendar", updatedCalendar);
    return data;
  } catch (error) {
    console.log("Error updating calendar: ", error);
  }
};
