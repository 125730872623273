import { $authHost } from ".";

export const getAllPackages = async () => {
  try {
    const { data } = await $authHost.get("api/packages");
    return data;
  } catch (error) {
    console.log("Error geting packages: ", error);
  }
};
export const putPackageByType = async ({ text, type, url }) => {
  try {
    const { data } = await $authHost.put("api/packages", { text, type, url });
    return data;
  } catch (error) {
    console.log(`Error puting package ${type}: `, error);
  }
};
