import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { Context } from "./index";
import { observer } from "mobx-react-lite";
import { check } from "./http/adminAPI";
import { Spinner } from "react-bootstrap";
import "./App.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

const App = observer(() => {
  const { admin } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    check()
      .then((data) => {
        admin.setAdmin(data);
        admin.setIsAuth(true);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Spinner animation={"grow"}></Spinner>;
  }

  return (
    <div className="app">
      <BrowserRouter>
        {admin.isAuth ? <NavBar /> : <></>}
        <AppRouter />
      </BrowserRouter>
      <Footer />
    </div>
  );
});

export default App;
