import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import "./styles/Clients.css";
import ServicesRow from "../components/ServicesRow";
import { addService } from "../http/serviceController";
import toast from "react-hot-toast";
import usePagination from "../components/hooks/usePagination";
import { ITEMS_PER_PAGE_COUNT } from "../utils/consts";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import useServices from "../components/hooks/useServices";
import { isNumeric } from "validator";

const Services = () => {
  const { page, handlePageChange } = usePagination(1);

  const { data, isLoading, refetch } = useServices(page);

  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    price: 0,
    url: "",
  });

  const resetState = () => {
    setFormValues({
      name: "",
      description: "",
      price: 0,
      url: "",
    });
  };

  const handleFormChange = (e, name) => {
    const value = e.target.value;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddService = async () => {
    if (!isNumeric(formValues.price) || parseFloat(formValues.price) <= 0) {
      toast.error("Некоректне значення ціни!");
      return;
    }
    const newService = {
      name: formValues.name,
      description: formValues.description,
      price: Number(formValues.price),
      url: formValues.url,
    };
    const result = await addService(newService);

    if (result) {
      toast.success("Послуга успішно змінена!");
    } else {
      toast.error("Щось пішло не так. Спробуйте пізніше!");
    }
    refetch();
    resetState();
    setShowModal(false);
  };

  if (isLoading) return null;

  return (
    <div className="root">
      <button
        className="add-service"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Додати послугу
      </button>
      <div className="user-table">
        <Table hover page-size="4">
          <thead>
            <tr>
              <th style={{ width: "20vw" }}>Назва послуги</th>
              <th style={{ width: "25vw" }}>Опис послуги</th>
              <th style={{ width: "15vw" }}>Фото (посилання)</th>
              <th style={{ width: "10vw" }}>Вартість</th>
              <th style={{ width: "7vw" }}>Дії</th>
            </tr>
          </thead>
          <tbody>
            {data.services && data.services.length > 0
              ? data.services.map((servicesItem) => {
                  return (
                    <ServicesRow key={servicesItem.id} service={servicesItem} />
                  );
                })
              : null}
          </tbody>
        </Table>
      </div>
      {data.totalPages <= 1 ? null : (
        <Pagination
          current={page}
          total={data.totalPages * ITEMS_PER_PAGE_COUNT}
          pageSize={ITEMS_PER_PAGE_COUNT}
          onChange={handlePageChange}
          className="pagination"
        />
      )}

      <Modal
        className="modalWindow"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size={"md"}
      >
        <Modal.Header>Додати послугу</Modal.Header>

        <Modal.Body className="modal-body">
          <div className="input_block">
            <p>Назва послуги</p>
            <input
              type="text"
              placeholder="Назва"
              value={formValues.name}
              onChange={(e) => handleFormChange(e, "name")}
            />
          </div>
          <div className="input_block">
            <p>Опис послуги</p>
            <textarea
              type="text"
              placeholder="Опис"
              onChange={(e) => handleFormChange(e, "description")}
              value={formValues.description}
            />
          </div>
          {formValues.url && (
            <div
              className="image-preview"
              style={{ backgroundImage: `url(${formValues.url})` }}
            />
          )}
          <div className="input_block">
            <p>Фото (посилання)</p>
            <input
              type="text"
              placeholder="Фото"
              value={formValues.url}
              onChange={(e) => handleFormChange(e, "url")}
            />
          </div>
          <div className="input_block">
            <p>Ціна послуги</p>
            <input
              type="text"
              placeholder="Ціна"
              value={formValues.price}
              onChange={(e) => handleFormChange(e, "price")}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={async () => {
              await handleAddService();
            }}
          >
            Зберегти зміни
          </button>
          <button
            onClick={() => setShowModal(false)}
            className="red-close-button"
          >
            Закрити
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Services;
