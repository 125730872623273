import { $authHost } from ".";

export const getAllServices = async (page) => {
  try {
    const { data } = await $authHost.get(`api/services?page=${page}`);
    return data;
  } catch (error) {
    console.log("Error geting all services: ", error);
  }
};

export const deleteServiceById = async (serviceId) => {
  try {
    const { data } = await $authHost.delete(`api/services/${serviceId}`);
    return data;
  } catch (error) {
    console.log(`Error deleting service with id ${serviceId}: `, error);
  }
};

export const updateService = async (serviceId, updatedService) => {
  try {
    const { data } = await $authHost.put(
      `api/services/${serviceId}`,
      updatedService
    );
    return data;
  } catch (error) {
    console.log(`Error updating service with id ${serviceId}: `, error);
  }
};
export const addService = async (newService) => {
  try {
    const { data } = await $authHost.post(`api/services`, newService);
    return data;
  } catch (error) {
    console.log(`Error creating service: `, error);
  }
};
