import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const usePagination = (initialPage = 1) => {
  const [page, setPage] = useState(initialPage);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const pageFromQuery = Number(searchParams.get("page"));
    if (pageFromQuery) {
      setPage(pageFromQuery);
    } else if (isNaN(pageFromQuery)) {
      setPage(initialPage);
    }

    console.log("page", page);
  }, [searchParams, initialPage, page]);

  const handlePageChange = (newPage) => {
    setSearchParams({ page: newPage });
    setPage(newPage);
  };

  return { page, handlePageChange };
};

export default usePagination;
