import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useRef } from "react";
import { Table } from "react-bootstrap";

import "./styles/Clients.css";
import toast from "react-hot-toast";

import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

import { useQueryClient } from "@tanstack/react-query";

import "rc-pagination/assets/index.css";
import usePackages from "../components/hooks/usePackages";
import { putPackageByType } from "../http/packageController";
import PackagesRow from "../components/PackagesRow";
import { isValidImageUrl, uploadImageToImbb } from "../utils/imbbUploader";

const Packages = observer(() => {
  const { data, isLoading } = usePackages();
  const fileInputRef = useRef(null);

  const [markDown, setMarkDown] = useState("");
  const [previewMarkDown, setPreviewMarkDown] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [packageToEdit, setPackageToEdit] = useState({
    id: "",
    text: "",
    type: "",
    url: "",
  });

  // Поточна дата для Preview
  const currentDate = new Date().toLocaleString('uk-UA', {
    hour: '2-digit',
    minute: '2-digit'
  });

  const queryClient = useQueryClient();

  const resetState = () => {
    setMarkDown("");
    setPreviewMarkDown("");
    setUrl("");
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const imageUrl = await uploadImageToImbb(file);
      if (imageUrl) {
        setUrl(imageUrl);
      }
    } catch (error) {
      console.error("Помилка завантаження файлу:", error);
      toast.error("Не вдалося завантажити зображення");
    } finally {
      setIsUploading(false);
    }
  };

  const handleEditPackage = async (e) => {
    e.preventDefault();

    const newPackage = {
      text: markDown,
      type: packageToEdit.type,
      url,
    };
    const result = await putPackageByType(newPackage);

    if (result) {
      toast.success("Пакет успішно оновлено!");
    } else {
      toast.error("Щось пішло не так. Спробуйте пізніше!");
    }
    setShowModal(false);
    resetState();
    queryClient.invalidateQueries("packages");
  };

  const handleMarkdown = (e) => {
    const value = e.target.value;
    setMarkDown(value);
    // Обробляємо markdown для відображення без зайвих переносів рядків
    // Замінюємо подвійні переноси рядків на спеціальний маркер, щоб зберегти їх
    const processedValue = value
      .replace(/\n\n/g, '\n&nbsp;\n')  // Зберігаємо подвійні переноси
      .replace(/__(.*?)__/g, "*$1*")
      .replace(/\*(.*?)\*/g, "**$1**");
    
    setPreviewMarkDown(processedValue);
  };

  useEffect(() => {
    if (packageToEdit && packageToEdit.text) {
      setMarkDown(packageToEdit.text);
      setPreviewMarkDown(
        packageToEdit.text
          .replace(/__(.*?)__/g, "*$1*")
          .replace(/\*(.*?)\*/g, "**$1**")
      );
      setUrl(packageToEdit.url);
    }
  }, [packageToEdit]);

  if (isLoading) return null;

  return (
    <div className="root">
      {!showModal ? (
        <>
          <div className="header">
            <h2>Управління пакетами послуг</h2>
          </div>
          <div className="user-table">
            <Table hover page-size="4">
              <thead style={{ width: "100%" }}>
                <tr>
                  <th style={{ width: "25vw" }}>Текст пакету</th>
                  <th style={{ width: "15vw" }}>Фото (посилання)</th>
                  <th style={{ width: "5vw" }}>Тип пакету</th>
                  <th style={{ width: "5vw" }}>Дії</th>
                </tr>
              </thead>
              <tbody>
                {data.messages && data.messages.length > 0
                  ? data.messages.map((msg) => {
                      return (
                        <PackagesRow
                          key={msg.id}
                          item={msg}
                          setPackage={setPackageToEdit}
                          setShowEditModal={setShowModal}
                        />
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <div className="notify-form">
          <form onSubmit={handleEditPackage}>
            <div className="input_block">
              <p>Текст</p>
              <textarea
                value={markDown}
                onChange={(e) => handleMarkdown(e)}
                placeholder="Введіть текст: *жирний*, _курсив_, натиснути Enter - перенос рядка, [Перейти на Google](https://www.google.com)"
                rows={20}
                cols={50}
              />
            </div>
            <div className="input_block">
              <p>Фото</p>
              <div className="file-upload-container">
                <input
                  type="text"
                  placeholder="Посилання на фото"
                  onChange={(e) => setUrl(e.target.value)}
                  value={url}
                  className="photo-url-input"
                />
                <div className="file-upload-actions">
                  <button 
                    type="button" 
                    className="action-btn"
                    onClick={() => fileInputRef.current.click()}
                    disabled={isUploading}
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                      <polyline points="17 8 12 3 7 8"></polyline>
                      <line x1="12" y1="3" x2="12" y2="15"></line>
                    </svg>
                    {isUploading ? "Завантаження..." : "Завантажити фото"}
                  </button>
                  {url && (
                    <button 
                      type="button" 
                      className="action-btn"
                      onClick={() => setUrl("")}
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="18" 
                        height="18" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                      Очистити
                    </button>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className="form-actions">
              <button type="submit" className="action-btn primary">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="18" 
                  height="18" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
                Зберегти зміни
              </button>
              <button
                type="button"
                className="action-btn"
                onClick={() => {
                  setShowModal(false);
                  resetState();
                }}
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="18" 
                  height="18" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
                Скасувати
              </button>
            </div>
          </form>
          <div className="preview">
            <div className="telegram-preview">
              <div className="telegram-preview-header">
                Перегляд повідомлення
              </div>
              <div className="telegram-message">
                <div className="telegram-avatar">
                  <div className="bot-avatar">FC</div>
                </div>
                <div className="telegram-content">
                  <div className="telegram-username">FC Мрія</div>
                  <div className="telegram-text">
                    <ReactMarkdown 
                      remarkPlugins={[remarkBreaks]} 
                      components={{
                        p: ({node, ...props}) => <div {...props} style={{marginBottom: '0.5em'}} />,
                        br: () => <br style={{display: 'block', margin: '0.3em 0'}} />
                      }}
                    >
                      {previewMarkDown}
                    </ReactMarkdown>
                  </div>
                  {url && isValidImageUrl(url) && (
                    <div className="telegram-image">
                      <img src={url} alt="Зображення до повідомлення" />
                    </div>
                  )}
                  {url && !isValidImageUrl(url) && (
                    <div className="telegram-image-placeholder">
                      <em>[Фото буде відображатись тут]</em>
                    </div>
                  )}
                  <div className="telegram-date">{currentDate}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Packages;
