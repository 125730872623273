import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Context } from "../index";
import { Link, useLocation } from "react-router-dom";
import "./styles/NavBar.css";
import {
  BUTTONS_ROUTE,
  CLIENTS_ROUTE,
  MAIN_ROUTE,
  NOTIFICATION_ROUTE,
  PACKAGES_ROUTE,
  SERVICES_ROUTE,
} from "../utils/consts";

const NavBar = () => {
  const { admin } = useContext(Context);
  const location = useLocation();

  const handleLogout = () => {
    admin.setAdmin(null);
    admin.setIsAuth(false);
    localStorage.removeItem("token");
    localStorage.removeItem("adminUsername");
  };

  return (
    <Navbar>
      <Nav>
        <div className="wrapper">
          <div className="image-block">
            <img src="https://fc-mriya.com.ua/wp-content/uploads/2024/10/logo.svg" alt="Logo" className="logo" />
          </div>
          <div className="leftSide">
            <Link
              to={MAIN_ROUTE}
              className={`navBtn ${
                location.pathname === MAIN_ROUTE ? "selectedPage" : ""
              }`}
            >
              Дашборд
            </Link>
            <Link
              to={CLIENTS_ROUTE}
              className={`navBtn spacedBtn ${
                location.pathname === CLIENTS_ROUTE ? "selectedPage" : ""
              }`}
            >
              Клієнти
            </Link>
            <Link
              to={SERVICES_ROUTE}
              className={`navBtn spacedBtn ${
                location.pathname === SERVICES_ROUTE ? "selectedPage" : ""
              }`}
            >
              Послуги
            </Link>
            <Link
              to={NOTIFICATION_ROUTE}
              className={`navBtn spacedBtn ${
                location.pathname === NOTIFICATION_ROUTE ? "selectedPage" : ""
              }`}
            >
              Оповіщення
            </Link>
            <Link
              to={BUTTONS_ROUTE}
              className={`navBtn spacedBtn ${
                location.pathname === BUTTONS_ROUTE ? "selectedPage" : ""
              }`}
            >
              Кнопки
            </Link>
            <Link
              to={PACKAGES_ROUTE}
              className={`navBtn spacedBtn ${
                location.pathname === PACKAGES_ROUTE ? "selectedPage" : ""
              }`}
            >
              Пакети
            </Link>
          </div>
        </div>

        <div className="rightSide">
          <span className="adminText">
            {localStorage.getItem("adminUsername")}
          </span>
          <button onClick={handleLogout} className="navBtn">
            <span>Вийти</span>
          </button>
        </div>
      </Nav>
    </Navbar>
  );
};

export default NavBar;
