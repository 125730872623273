import { useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles/UserRow.css";
import { Pencil } from "lucide-react";
import toast from "react-hot-toast";
import { shortenImageUrl } from "../utils/imageShortener";
import { deleteNotification } from "../http/notificationController";
import { useQueryClient } from "@tanstack/react-query";

const NotificationsRow = ({
  notification,
  setIsEditModal,
  setNotification,
  setShowEditModal,
}) => {
  const [showModal, setShowModal] = useState(false);

  const queryClient = useQueryClient();

  const handleDeleteNotification = async (notificationId) => {
    const result = await deleteNotification(notificationId);

    if (result) {
      toast.success("Оповіщення успішно видалено!");
    } else {
      toast.error("Щось пішло не так. Спробуйте пізніше!");
    }
    queryClient.invalidateQueries("notifications");
  };

  return (
    <tr key={notification.id}>
      <td>{notification.text || "--"}</td>
      <td>{shortenImageUrl(notification.photo) || "--"}</td>
      <td>{notification.link || "--"}</td>
      <td>
        <div className="actionBlock">
          <button
            onClick={() => {
              setIsEditModal(true);
              setNotification({
                id: notification.id,
                text: notification.text,
                photo: notification.photo,
                link: notification.link,
              });
              setShowEditModal(true);
            }}
            className="blockUserBtn"
          >
            <Pencil alt="Edit notification" title="Edit notification" />
          </button>
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="deleteUserBtn"
          >
            <img
              src="/clientsImg/trash.svg"
              alt="Delete notification"
              title="Delete notification"
            />
          </button>
        </div>
      </td>
      <Modal
        className="modalWindow"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size={"md"}
      >
        <Modal.Header>Точно бажаєте видалити оповіщення?</Modal.Header>
        <Modal.Footer>
          <>
            <button
              className="black-close-button"
              onClick={async () => {
                await handleDeleteNotification(notification.id);
                setShowModal(false);
              }}
            >
              Видалити
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="red-close-button"
            >
              Закрити
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

export default NotificationsRow;
