import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { login } from "../http/adminAPI";
import { Context } from "../index";
import { MAIN_ROUTE } from "../utils/consts";
import "./styles/Login.css";

const Login = observer(() => {
  const { admin } = useContext(Context);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signIn = async () => {
    if (!username || !password) {
      setError("Введіть логін та пароль");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      await login(username, password)
        .then(data => {
          admin.setAdmin(data.admin);
          admin.setIsAuth(true);
          navigate(MAIN_ROUTE);
        })
        .catch(e => {
          setError(e.response?.data?.message || "Помилка авторизації");
        });
    } catch (e) {
      setError(e.response?.data?.message || "Помилка авторизації");
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await signIn();
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-card">
          <div className="login-logo-container">
            <img src="https://fc-mriya.com.ua/wp-content/uploads/2024/10/logo.svg" alt="FC Мрія" className="logo-image" />
          </div>
          
          <div className="login-form-container">
            <h1 className="login-title">Адміністративна панель</h1>
            <p className="login-subtitle">Авторизуйтеся, щоб продовжити</p>
            
            {error && <div className="login-error">{error}</div>}
            
            <Form className="login-form">
              <Form.Group className="login-form-group">
                <div className="input-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <Form.Control
                  className="login-input"
                  type="text"
                  placeholder="Логін адміністратора"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="login-form-group">
                <div className="input-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                  </svg>
                </div>
                <InputGroup className="password-group">
                  <Form.Control
                    className="login-input"
                    type={showPassword ? "text" : "password"}
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="password-toggle"
                  >
                    {showPassword ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                        <line x1="1" y1="1" x2="23" y2="23"></line>
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    )}
                  </button>
                </InputGroup>
              </Form.Group>

              <Button 
                className="login-button" 
                onClick={signIn}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="button-loader">
                    <div className="spinner"></div>
                    <span>Авторизація...</span>
                  </div>
                ) : "Увійти в систему"}
              </Button>
            </Form>
            
            <div className="login-footer">
              <div className="fc-badge">
                <span className="fc-badge-accent">FC</span> Мрія
              </div>
              <p className="login-copyright">© 2025 Адміністративна панель</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Login;
